import { useEffect, useRef, useState, useContext } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
//import PropTypes from "prop-types";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

// Datatables
import $ from "jquery";
import DataTables from "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.min.css";

$.Datatable = require("datatables.net");
$.DataTable = require('datatables.net-select');
$.DataTable = require('datatables.net-buttons');
$.DataTable = require('datatables.net-buttons-dt');
$.DataTable = require('datatables.net-buttons/js/buttons.html5');


const ProcessorJobList = () => {
	// Get event data from API
	const { jwt } = useContext(AuthContext);
	const navigate = useNavigate();
	const tableRef = useRef();
	const [isMounted, setIsMounted] = useState(false);
	const [jobList, setJobList] = useState(0);
	const [loading, setLoading] = useState(false);

	const restEndPoint = `https://afccampaigneventprocessor2022-v2.azurewebsites.net/api/collectstatus`;

	const callRestApi = async() => {
		const resp = await fetch(restEndPoint);
		//const jsonResp = await resp.json();
		console.log(resp);
	};

	callRestApi();

	/*
	useEffect(() => {
		if (!isMounted) {
			setIsMounted(true);
			$(tableRef.current).DataTable({
			data: [],
				dom: 'fBtlip',
				columns: [
					{ title: 'ID', visible: false },
					{ title: 'Job Name' },
				],
				order: [[0, 'ID']],
				options: {
				processing: true,
			}
		//order: [[2, 'desc'],[4, 'desc']],
		});
		//console.log("jwt: ", jwt);

		getJobList();
		}
	});


	const getJobList = async () => {
        try {
            //setLoading(true);
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.get(
                `https://afccampaigneventprocessor2022-v2.azurewebsites.net/api/collectstatus`,
                headers
            );
            console.log(res);

            
            const newList = [];
            for (let i = 0; i < res.data.values.length; i++) {
                newList.push(
                    [
                        res.data.values[i]['id'],
                        res.data.values[i]['name'],
                        res.data.values[i]['CampaignName'],
                        res.data.values[i]['eventStart'],
                        res.data.values[i]['status'],
                        res.data.values[i]['qtyProcessed'],
                        res.data.values[i]['qtyCollected'],
                        res.data.values[i]['qtyPending'],
                        res.data.values[i]['qtyError'],
                        res.data.values[i]['qtySuccess'],
                        res.data.values[i]['KeepAliveTimeStamp'],
                        res.data.values[i]['HealthCheckQty'],
                        res.data.values[i]['ProcessorID'],
                        res.data.values[i]['PendingEvents'],
                    ]
                );
            }
            setJobList(newList);


        } catch (err) {
            console.log(err.response.data.message);
        }
        setLoading(false);
    };
	*/


	return (
		<div>
			<table ref={tableRef} className="compact hover" style={{width: "100%", cursor: "pointer"}}></table>
		</div>


	);    

};

export default ProcessorJobList;