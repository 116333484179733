import { useEffect, useRef, useState, useContext, forwardRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams } from "react-router-dom";

import { browserName, browserVersion } from "react-device-detect";

const DataMapper = ({ open, onClose }) => {
    const { jwt, dispatch } = useContext(AuthContext);

    if(!open) return null;

    return (
        <div className="overlay">
            <div className="modalContainer" style={{width: "2000px"}}>
                Hello World

                <div className="w-96" />

                <div>
                    <p onClick={onClose}>X</p>
                </div>

            </div>
        </div>
    );

};

DataMapper.propTypes = {
    open: PropTypes.bool.isRequired, onClose: PropTypes.func
};

export default DataMapper;