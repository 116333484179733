import { useEffect, useRef, useState, useContext, forwardRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams } from "react-router-dom";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

// Notifications
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const NewCampaign = () => {
    const { jwt, dispatch, first_name, last_name } = useContext(AuthContext);

    const [campaign, setCampaign] = useState(
    {
        campaignName: '',
        stateID: 0,
        stateName: '',
        raceTypeID: 0,
        raceLevelID: 0,
        preferredAreaCode: '',
    });

    const [campaignName, setCampaignName] = useState('');
    const [stateID, setStateID] = useState(0);
    const [stateName, setStateName] = useState('');
    const [stateAbbreviation, setStateAbbreviation] = useState('');
    const [raceTypeID, setRaceTypeID] = useState(0);
    const [raceLevelID, setRaceLevelID] = useState(0);
    const [preferredAreaCode, setPreferredAreaCode] = useState('');

    const [states, setStates] = useState(0);
    const [raceTypes, setRaceTypes] = useState(0);

    const [timezone, setTimezone] = useState(0);


    // Called anytime userList is modified
    useEffect(() => {
        if (states == 0) {
            getStates();
        }
        if (raceTypes == 0) {
            getRaceTypes();
        }
    });

    // Get list of states and info from API
    const getStates = async () => {
        try {
            //setLoading(true);
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.get(
                `${config.reportapi.url}/api/v1/event/collectState`,
                headers
            );

            console.log("getState(): results = ", res.data);
            setStates(res.data.values);
        } catch (err) {
            console.log(err.response.data.message);
        }

        //setLoading(false);
    };

    // Get list of race types from API
    const getRaceTypes = async () => {
        try {
            //setLoading(true);
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.get(
                `${config.reportapi.url}/api/v1/event/collectRaceType`,
                headers
            );

            console.log("getRaceTypes(): results = ", res.data);
            setRaceTypes(res.data.values);
        } catch (err) {
            console.log(err.response.data.message);
        }

        //setLoading(false);
    };

    // Get selected state details from API
    const getStateInfo = async (state_id) => {
        var stateInfo = [];
        try {
            //setLoading(true);
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.get(
                `${config.reportapi.url}/api/v1/event/collectAllStateInformation?state_id=${state_id}`,
                headers
            );

            console.log("getStateInfo(): results = ", res.data);
            stateInfo = res.data.values;
        } catch (err) {
            console.log(err.response.data.message);
        }

        let acList = [];
        for(let i=0; i < stateInfo.length; i++) {
            acList.push(stateInfo[i]['AreaCode']);
        }
        var x = document.getElementById("areacodelist");
        x.innerHTML = `${stateInfo[0]['StateName']}: ${acList.toString()}`;

        setStateAbbreviation(stateInfo[0]['StateAbbr']);

        if (stateInfo[0]['TimeZoneName'].startsWith("Hawaiian")) {
            setTimezone('hawaii');
        } else if (stateInfo[0]['TimeZoneName'].startsWith("Alaska")) {
            setTimezone('alaska');
        } else if (stateInfo[0]['TimeZoneName'].startsWith("Pacific")) {
            setTimezone('pacific');
        } else if (stateInfo[0]['TimeZoneName'].startsWith("Mountain")) {
            setTimezone('mountain');
        } else if (stateInfo[0]['TimeZoneName'].startsWith("Arizona")) {
            setTimezone('arizona');
        } else if (stateInfo[0]['TimeZoneName'].startsWith("Central")) {
            setTimezone('central');
        } else if (stateInfo[0]['TimeZoneName'].startsWith("Eastern")) {
            setTimezone('eastern');
        }

        //setLoading(false);
    };

    // Post new campaign data to API
    const postNewCampaign = async (campaign_fields) => {
        let jsonData = campaign_fields['sql'];
        try {
            //setLoading(true);

            // post to sql
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const resSQL = await axios.post(
                `${config.reportapi.url}/api/v1/event/createCampaign?company_id=${campaign_fields['sql']['company_id']}`,
                jsonData,
                headers
            );
            console.log("NewCampaign call to postNewCampaign (sql): ",resSQL.data);

            let new_campaign_id_legacy = resSQL.data.values[0].the_ouput;
            console.log("NewCampaign call to postNewCampaign new_campaign_id_legacy = ",new_campaign_id_legacy);

            // post to mongo
            campaign_fields['mongo']['campaign_id_legacy'] = new_campaign_id_legacy.toString();
            jsonData = campaign_fields['mongo'];
            const resMongo = await axios.post(
                `${config.accountapi.url}/api/v1/campaigns`,
                jsonData,
                headers
            );
            console.log("NewCampaign call to postNewCampaign (mongo): ",resMongo.data);

            // user_key = res.data.key;
            // userDetails = res.data;
            // setSuccessOpen(true);
            // setSuccessMessage(`User Account Created`);
            // navigate("/");
        } catch (err) {
            //setLoading(false);
            console.log(err.response.data.message);
            if (err.response.status == 401) {
                //setErrorOpen(true);
                //setErrorMessage(`Message Failed: ${err.response.data.message}`);
                //dispatch({ type: "LOGOUT" });
            }
            if (err.response.status == 400) {
                //setErrorOpen(true);
                //setErrorMessage(`Message Failed: ${err.response.data.message}`);
            }
        }
    };

    const onStateChange = (event) => {
        setStateID(parseFloat(event.target.value));
        //setStateName(event.target.selectedOptions[0].label);
        for (let i=0; i<states.length; i++) {
            if (event.target.value == states[i]['StateID']) {
                setPreferredAreaCode(states[i]['PreferredAreaCode']);
                getStateInfo(states[i]['StateID']);
                break;
            }
        }

    }

    const onRaceTypeChange = (event) => {
        setRaceTypeID(parseFloat(event.target.value));
        //setRaceTypeName(event.target.selectedOptions[0].label);
    }

    function handleSubmit(e) {
        e.preventDefault();

        console.log("handleSubmit: entered");

        let sql_campaign_fields = {}

        sql_campaign_fields['company_id'] = 16;
        sql_campaign_fields['owner'] = first_name + " " + last_name;

        if (campaignName != '') {
            sql_campaign_fields['campaign_name'] = campaignName;
        }
        if (stateID != '') {
            sql_campaign_fields['state_id'] = stateID;
        }
        if (raceTypeID != '') {
            sql_campaign_fields['racetype_id'] = raceTypeID;
        }
        if (preferredAreaCode != '') {
            sql_campaign_fields['preferred_area_code'] = preferredAreaCode;
        }

        console.log("handleSubmit: sql_campaign_fields = ", sql_campaign_fields);

        // need new sql id for account_id_legacy field
        let mongo_campaign_fields = {}
        mongo_campaign_fields['business_key'] = 'c18cc89bf7c6bd3655c33a8c2338c81f9d8f58c0';
        mongo_campaign_fields['name'] = campaignName;
        mongo_campaign_fields['campaign_id_legacy'] = '';
        // mongo_campaign_fields['admin_bw_location_id'] = '';
        // mongo_campaign_fields['customer_bw_location_id'] = '';
        // mongo_campaign_fields['admin_bw_application_id'] = '';
        // mongo_campaign_fields['customer_bw_application_id'] = '';
        mongo_campaign_fields['sms_base_price_contract'] = 0.0;
        mongo_campaign_fields['mms_base_price_contract'] = 0.0;
        mongo_campaign_fields['mms_included_characters'] = 0;
        mongo_campaign_fields['afc_connect_base_price_contract'] = 0.0;
        mongo_campaign_fields['afc_crystal_pricing'] = 0.0;
        mongo_campaign_fields['additional_segment_price'] = 0.0;
        mongo_campaign_fields['bandwidth_campaign_id'] = '';
        mongo_campaign_fields['approval_numbers'] = [];
        mongo_campaign_fields['state'] = stateAbbreviation;  // state appreviation
        mongo_campaign_fields['timezone'] = timezone;

        console.log("handleSubmit: mongo_campaign_fields = ", mongo_campaign_fields);

        if (Object.keys(sql_campaign_fields).length > 0) {
            let campaign_fields = {
                'sql' : sql_campaign_fields,
                'mongo' : mongo_campaign_fields
            }

            postNewCampaign(campaign_fields);
        }
    }

    const validateForm = () => {
        if (campaignName === '' || stateID === 0 || raceTypeID === 0) {
            return false;
        }
        return true;
    };

    return (
        <div>
            <div className="flex" style={{margin: "50px 0px 0px 0px"}}>
                <div className="bg-blue-50" style={{width: "40vw"}}>

                    <div style={{margin: "40px 0px 0px 0px"}}>
                        <div className="flex" style={{margin: "0px 10px 0px 15vw"}}>
                            <div style={{width: "30px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#4a4a4a" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <div className="text-sm" style={{margin: "0px 10px 0px 10px"}}>
                                Manage common messages for either a customer or geographic region, or both into a single functional campaign.
                            </div>
                        </div>
                    </div>

                    <div style={{margin: "40px 0px 0px 0px"}}>
                        <div className="flex" style={{margin: "0px 10px 0px 15vw"}}>
                            <div style={{width: "30px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#4a4a4a" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <div className="text-sm" style={{margin: "0px 10px 0px 10px"}}>
                                Your Opt-Out responses are automatically managed and curiated from your lists. One more way we empower you to focus on the message... not the minutia.
                            </div>
                        </div>
                    </div>

                    <div style={{margin: "40px 0px 0px 0px"}}>
                        <div className="flex" style={{margin: "0px 10px 0px 15vw"}}>
                            <div style={{width: "30px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#4a4a4a" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <div className="text-sm" style={{margin: "0px 10px 0px 10px"}}>
                                Your message phone numbers will be automatically provisioned based on the preference order you designated here.
                            </div>
                        </div>
                    </div>

                    <img src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Header_Curves.png" />

                </div>

                <div className="bg-white" style={{padding: "0px 0px 0px 50px", width: "600px"}}>
                    <form onSubmit={handleSubmit}>
                        <div style={{margin: "30px 0px 60px 0px"}}>
                            <br/><h6>Create Your New Messaging Campaign</h6><br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="campaignName">Camapaign Name:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="campaignName" name="campaignName" value={campaignName} onChange={e => setCampaignName(e.target.value)} placeholder={'Campaign Name'}/>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="state">State:</label>
                                <select id="state" className="select select-bordered w-full max-w-xs" onChange={(e) => { onStateChange(e); }}>
                                    <option disabled selected>Campaign State</option>
                                    {states
                                        ? states.map((state) => {
                                            return <option key={state.StateID} value={state.StateID}>{state.StateName}</option>;
                                        })
                                        : null}
                                </select>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="racetype">Race Type:</label>
                                <select id="racetype" className="select select-bordered w-full max-w-xs" onChange={(e) => { onRaceTypeChange(e); }}>
                                    <option disabled selected>Race Level | Race Type</option>
                                    {raceTypes
                                        ? raceTypes.map((raceType) => {
                                            return <option key={raceType.RaceTypeID} value={raceType.RaceTypeID}>{raceType.RaceTypeName}</option>;
                                        })
                                        : null}
                                </select>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="preferredAreaCode">Preferred Area Code:</label>
                                <p id="areacodelist"></p>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="preferredAreaCode" name="preferredAreaCode" value={preferredAreaCode} onChange={e => setPreferredAreaCode(e.target.value)} placeholder={'Preferred Area Code'}/>
                            </div>
                            <br/>

                            <button className="btn btn-outline bg-blue-200 mt-5 w-60" type="submit" disabled={!validateForm()}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );

};

export default NewCampaign;
