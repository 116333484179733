import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { CompanyContextProvider } from "./context/CompanyContext";
import { EventReportContextProvider } from "./context/EventReportContext";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <AuthContextProvider>
        <CompanyContextProvider>
            <EventReportContextProvider>
                <App />
            </EventReportContextProvider>
        </CompanyContextProvider>
    </AuthContextProvider>
    // </React.StrictMode>
);
