const production = {
    messageapi: {
        url: "https://afmessage.roosevelt.hyperscaleaf.com",
    },
    accountapi: {
        url: "https://afaccount.roosevelt.hyperscaleaf.com",
    },
    voterapi: {
        url: "https://afvoter.roosevelt.hyperscaleaf.com",
    },
    eventapi: {
        url: "https://afevent.roosevelt.hyperscaleaf.com",
    },
    reportapi: {
        url: "https://afdashboardapi.roosevelt.hyperscaleaf.com",
    },
};

const development = {
    messageapi: {
        url: process.env.REACT_APP_MESSAGEAPI,
    },
    accountapi: {
        url: process.env.REACT_APP_ACCOUNTAPI,
    },
    voterapi: {
        url: process.env.REACT_APP_VOTERAPI,
    },
    eventapi: {
        url: process.env.REACT_APP_EVENTAPI,
    },
    reportapi: {
        url: process.env.REACT_APP_REPORTAPI,
    },
};

export const config =
    process.env.NODE_ENV === "development" ? development : production;
