import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import Login from "../login/Login";

import Topbar from "../../components/topbar/Topbar";
import AdminUserEdit from "../../components/admin/AdminUserEdit";
import { createSearchParams, useLocation } from "react-router-dom";

export default function Dashboard() {
    // Validate authentication
    const { jwt, dispatch } = useContext(AuthContext);
    if (jwt === null) {
        return <Login fullscreen={true} />;
    }

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const user_key = searchParams.get('user_key');

    useEffect(() => {
        document.title = "Alliance Forge | Admin";
        //console.log("JWT = ", jwt);
    }, [jwt]);

    return (
        <div>
            <div className="accountHeader">
                <div className="adminHeaderName flex">
                    <img className="adminHeaderImage" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Flame_Icon_ShapeFile_BW.png" />

                    <div className="adminHeaderTitle">Admin</div>
                    <div className="adminHeaderTitle2">Portal</div>
                </div>
                <div className="adminHeaderDescription">user edit</div>
            </div>

            <Topbar />

            <div className="flex">

                <div className="userEditLogo">

                    <div style={{margin: "100px 0px 0px 0px", opacity: "100%"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={"300px"} height={"300px"} viewBox="0 0 24 24" fill="none" stroke="#ffffff" 
                            strokeWidth=".50" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/><circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/>
                        </svg>
                    </div>
                </div>

                <div className="flex userEditTile">
                    <AdminUserEdit user_key={user_key}/>
                </div>
            </div>


        </div>
    );
}
