import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import Login from "../login/Login";
import { Link } from "react-router-dom";

import Topbar from "../../components/topbar/Topbar";
import AdminUserList from "../../components/admin/AdminUserList";
import ProcessorJobList from "../../components/admin/ProcessorJobList"

export default function Dashboard() {
    // Validate authentication
    const { jwt } = useContext(AuthContext);
    if (jwt === null) {
        return <Login fullscreen={true} />;
    }

    //const [totMemberCountFormatted, setTotalSent] = useState(0);

    useEffect(() => {
        document.title = "Alliance Forge | Admin";
        //console.log("JWT = ", jwt);
    }, [jwt]);

    return (
        <div>
            <div className="accountHeader">
                <div className="adminHeaderName flex">
                    <img className="adminHeaderImage" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Flame_Icon_ShapeFile_BW.png" />

                    <div className="adminHeaderTitle">Admin</div>
                    <div className="adminHeaderTitle2">Portal</div>
                </div>
                <div className="adminHeaderDescription">dashboard</div>
            </div>

            <Topbar />

            <div className="mainBody">
                <div className="flex">
                    <div style={{margin: "0px 0px 0px 30px"}}>
                        <Link to="/useredit?user_key=new">
                            <button type="button">
                                <div className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" fill="none" stroke="black" 
                                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line>
                                    </svg>
                                    <div style={{margin: "5px 5px 0px 10px"}}>Create New User</div>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div style={{margin: "0px 0px 0px 30px"}}>
                        <Link to="/campaignCreate">
                            <button type="button">
                                <div className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" fill="none" stroke="black" 
                                        strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" 
                                        width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect>
                                    </svg>
                                    <div style={{margin: "5px 5px 0px 10px"}}>New Campaign (BETA)</div>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div style={{margin: "0px 0px 0px 30px"}}>
                        <Link to="/EventCreate">
                            <button type="button">
                                <div className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2" 
                                    strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
                                    <div style={{margin: "5px 5px 0px 10px"}}>New Event (BETA)</div>
                                </div>
                            </button>
                        </Link>
                    </div>                            
                </div>

                <div className="flex space-x-4 bg-white mt-5">
                    {/*}<ProcessorJobList />{*/}
                    <AdminUserList />
                </div>
            </div>
        </div>
    );
}
