import { useEffect, useRef, useState, useContext, forwardRef, MouseEvent, onScroll, useCallback } from "react";
import { AuthContext } from "../../context/AuthContext";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams } from "react-router-dom";

import { browserName, browserVersion } from "react-device-detect";
import ReactPlayer from 'react-player';

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

// Notifications
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

//datepicker
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import TextField from "@mui/material/TextField";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

//DATA MAPPER
import DataMapperModal from "../datamapper/DataMapperModal.jsx";
import DataMapper from "../datamapper/DataMapper.jsx";

//EVENT MESSAGE
import Listbox from "react-widgets/Listbox";

const NewEvent = () => {
    const { jwt, dispatch } = useContext(AuthContext);

    const [eventType, setEventType] = useState(1);
    const [eventName, setEventName] = useState('');
    const [messagePurposeID, setMessagePurposeID] = useState(0);
    const [costPerMessage, setCCostPerMessage] = useState(0);
    const [eventID, setEventID] = useState(0);
    const [projectCode, setProjectCode] = useState('');
    const [paidForByCompliant, setPaidForByCompliant] = useState(false);
    const [eventTime, setEventTime] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [mmsAssetURL, setMMSAssetURL] = useState('');
    const [sms, setSMS] = useState('');
    const [sampleSMS, setSampleSMS] = useState('');
    const [adminPhoneNumber, setAdminPhoneNumber] = useState('');
    const [transmissionPhoneNumber, setTransmissionPhoneNumber] = useState('');

    const [messengerServiceIDAdmin, setMessengerServiceIDAdmin] = useState('');
    const [messengerServiceIDCust, setMessengerServiceIDCust] = useState('');

    const [smsBasePrice, setSMSBasePrice] = useState(0);
    const [mmsBasePrice, setMMSBasePrice] = useState(0);
    const [addtlSegmentPrice, setAddtlSegmentPrice] = useState(0);

    const [preferredAreaCode, setPreferredAreaCode] = useState('');

    const [rotationFactorUniverse, setRotationFactorUniverse] = useState(0);
    const [rotationFactorMessage, setRotationFactorMessage] = useState(0);
    const [rotationFactorTiming, setRotationFactorTiming] = useState(0);
    const [rotationFactorSample, setRotationFactorSample] = useState(0);
    const [rotationFactorApprovers, setRotationFactorApprovers] = useState(0);
    const [rotationFactorNewApprover, setRotationFactorNewApprover] = useState(0);
    const [rotationFactorNewSampleNB, setRotationFactorNewSampleNB] = useState(0);
    const [rotationFactorSampleNB, setRotationFactorSampleNB] = useState(0);

    const [universeClickMessage, setUniverseClickMessage] = useState('Click me to show universe mapping details');
    const [timingClickMessage, setTimingClickMessage] = useState('Click me to show scheduling details');
    const [msgClickMessage, setMsgClickMessage] =useState('Click me to show message details');
    const [sampleClickMessage, setSampleClickMessage] =useState('Click me to show message sample data details');
    const [approverClickMessage, setApproverClickMessage] =useState('Click me to show the selected approvers for your message');
    const [sampleNBClickMessage, setSampleNBClickMessage] =useState('Click me to show the non-branded samples for your message');
    const [newApproverClickMessage, setNewApproverClickMessage] =useState('Click me to create a New Approver');
    const [newSampleNBClickMessage, setNewSampleNBClickMessage] =useState('Click me to create a New Non Branded Sample');
    const [approverPromptVisibility, setApproverPromptVisibility] = useState(true)

    const [newApproverName, setNewApproverName] = useState('');
    const [newApproverPhone, setNewApproverPhone] = useState('');
    const [newSampleNBName, setNewSampleNBName] = useState('');
    const [newSampleNBPhone, setNewSampleNBPhone] = useState('');
    const [sampleNBPromptVisibility, setSampleNBPromptVisibility] = useState(true)

    const [openDataMapperModal, setOpenDataMapperModal] = useState(false);

    const dragItem = useRef();
    const dragOverItem = useRef();
    const [optOutList, setOptOutList] = useState(['[%OptOutMessage-Classic%]','[%Double Opt-In/Out%]','[%OptOutMessage-Stop=End%]',
            '[%OptOutMessage-Stop2End%]','[%OptOutMessage-StopToEnd%]']);
    const [msgList, setMsgList] = useState(['[%URL%]','[%County%]']);
    const [memberList, setMemberList] = useState(['[%MemberFirstName%]','[%MemberLastName%]','[%MemberName%]']);

    //SAMPLE DATA SECTION
    const [sampleFirstName, setSampleFirstName] = useState('George');
    const [sampleLastName, setSampleLastName] = useState('Washington');
    const [sampleURL, setSampleURL] = useState('https://www.foo.com');
    const [sampleCounty, setSampleCounty] = useState('Fairfax');
    const [sampleUID, setSampleUID] = useState('DCBD8AAC-AE0B-BE34-8CF4-FBEFBD8E5111');

    //APPROVERS
    type approver = {id: 0, ApproverName: '', ApproverPhone: '', ApprovalStatus: '', ApprovalMessageSent: '', ApprovalMessageReceived: ''};

    const existingApprovers: approver[] = [
            {
                id: 0, ApproverName: 'Dave Espinosa', ApproverPhone: '7752339418'
            },
            {
                id: 1, ApproverName: 'Rick Ross', ApproverPhone: '7752256204'
            },
            {
                id: 2, ApproverName: 'Cynthia Espinosa', ApproverPhone: '7752339418'
            }
        ]

    const [eventApprovers, setEventApprovers] = useState([]);
    const [existingApproversList, setExistingApproversList] = useState([]);

    //APPROVERS
    type sampleNB = {id: 0, SampleName: '', SamplePhone: '', SampleStatus: '',SampleMessageSent: '', SampleMessageReceived: ''};

    const existingSamples: sampleNB[] = [
            {
                id: 0, SampleName: 'Dave Espinosa', SamplePhone: '7752339418'
            },
            {
                id: 1, SampleName: 'Rick Ross', SamplePhone: '7752256204'
            },
            {
                id: 2, SampleName: 'Cynthia Espinosa', SamplePhone: '7752339418'
            }
        ]

    const [eventSamplesNB, setEventSamplesNB] = useState([]);
    const [existingSamplesNBList, setExistingSamplesNBList] = useState([]);    

    //sticky side bar
    const [y, setY] = useState(window.scrollY);
    const [scrollTop, setScrollTop] = useState(false);


    function handleOnDrag(e, widgetType){
        e.dataTransfer.setData("widgetType", widgetType);
    }

    const handleOnMouseMove = (e) => {
        
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };
   

    function handleOnDrop(e) {
        //console.log("x", e.clientX);
        //console.log("y", e.clientY);
        //console.log("caret", document.caretPositionFromPoint(e.clientX, e.clientY));

        const caretPosition = document.caretPositionFromPoint(e.clientX, e.clientY).offset;

        const startString = sms.substring(0, caretPosition);
        const endString =  sms.substring(caretPosition, caretPosition.length);

        let smsval = startString.trim() + ' ' + e.dataTransfer.getData("widgetType") + ' ' + endString.trim();

        const widgetType = e.dataTransfer.getData("widgetType");
        
        //smsval = smsval + widgetType;
        setSMS(smsval.trim());

        createSampleSMSBody(smsval.trim());
        //console.log("sms", smsval);
        //console.log("startString", startString);
        //console.log("endString", endString);
    }

    function handleApproverOnDrag(e, lapprover){
        e.dataTransfer.setData("approver", lapprover);
    }

    function handleApproverOnDrop(e){

        var _id = e.dataTransfer.getData("approver");

        var _existing = eventApprovers.find((approver) => {
            return approver.id == _id;
        })

        if(_existing == null)
        {
            var _lnewApprover:approver = existingApprovers.find((approver) => {
                return approver.id == _id;
            })

            _lnewApprover.ApprovalStatus = 'PENDING'

            var _a = eventApprovers;
            var _b = [];
            _b.push(_lnewApprover);

            var newArray = [..._a, ..._b];

            setEventApprovers(newArray);

            // if(eventApprovers.length > 0)
            // {
                setApproverPromptVisibility(false);
            //}
            

            //console.log("new approver found:", _lnewApprover.ApproverName);
        }

        //console.log(_id);
        //console.log("event approvers count:", eventApprovers.length);

    }

    function Approver_RemoveApproverClick(approver){


        var _id = approver.id;

        //CALL SQL STORED PROC HERE

        var _a = [...eventApprovers];

        var _i = 0;

        for (let i = 0; i < _a.length; i++){
            if(_a[i].id == _id)
            {
                _i = i;
                break; 
            }
            
        }

        _a.splice(_i, 1);


        setEventApprovers(_a);

        if(_a.length != 0)
        {
            //console.log("remove approver:", approver.ApproverName);
            setApproverPromptVisibility(false);
        }
        else
        {
            setApproverPromptVisibility(true);
        }

    }

    function createNewApprover()
    {
        var _found = false;
        var _a = [...eventApprovers];
        var _e = [...existingApprovers];

        for (let i = 0; i < _a.length; i++){
            if(_a[i].ApproverPhone == newApproverPhone){
                _found = true;
                break;
            }
        }

        if(_found == false)
        {
            var _new =  {id: existingApprovers.length, ApproverName: newApproverName, ApproverPhone: newApproverPhone, ApprovalStatus: 'PENDING'};
            _a.splice(_a.length, 0, _new);
            _e.splice(_a.length, 0, _new);

            setEventApprovers(_a);
            setExistingApproversList(_e);
        }
        else
        {
            //TODO Need Toast Alert Here
        }

        if(_a.length != 0)
        {
            //console.log("remove approver:", approver.ApproverName);
            setApproverPromptVisibility(false);
        }
        else
        {
            setApproverPromptVisibility(true);
        }

    }

    function handleSampleOnDrag(e, lsample){
        e.dataTransfer.setData("sample", lsample);
    }

    function createNewSampleNB(){
        var _found = false;
        var _a = [...eventSamplesNB];
        var _e = [...existingSamples];

        for (let i = 0; i < _a.length; i++){
            if(_a[i].SamplePhone == newSampleNBName){
                _found = true;
                break;
            }
        }

        if(_found == false)
        {
            var _new =  {id: existingSamples.length, SampleName: newSampleNBName, SamplePhone: newSampleNBPhone, SampleStatus: 'PENDING'};
            _a.splice(_a.length, 0, _new);
            _e.splice(_a.length, 0, _new);

            setEventSamplesNB(_a);
            setExistingSamplesNBList(_e);
        }
        else
        {
            //TODO Need Toast Alert Here
        }

        if(_a.length != 0)
        {
            //console.log("remove approver:", approver.ApproverName);
            setSampleNBPromptVisibility(false);
        }
        else
        {
            setSampleNBPromptVisibility(true);
        }
    }

    function handleSampleNBOnDrop(e){

        var _id = e.dataTransfer.getData("sample");

        var _existing = eventSamplesNB.find((sample) => {
            return sample.id == _id;
        })

        if(_existing == null)
        {
            var _lnewSampleNB:sampleNB = existingSamples.find((sample) => {
                return sample.id == _id;
            })

            _lnewSampleNB.SampleStatus = 'PENDING'

            var _a = eventSamplesNB;
            var _b = [];
            _b.push(_lnewSampleNB);

            var newArray = [..._a, ..._b];

            setEventSamplesNB(newArray);

            // if(eventApprovers.length > 0)
            // {
                setSampleNBPromptVisibility(false);
            //}
            

            //console.log("new approver found:", _lnewApprover.ApproverName);
        }

        //console.log(_id);
        //console.log("event approvers count:", eventApprovers.length);

    }

    function SampleNB_RemoveSampleNBClick(sample){


        var _id = sample.id;

        //CALL SQL STORED PROC HERE

        var _a = [...eventSamplesNB];

        var _i = 0;

        for (let i = 0; i < _a.length; i++){
            if(_a[i].id == _id)
            {
                _i = i;
                break; 
            }
            
        }

        _a.splice(_i, 1);

        setEventSamplesNB(_a);

        if(_a.length != 0)
        {
            //console.log("remove approver:", approver.ApproverName);
            setSampleNBPromptVisibility(false);
        }
        else
        {
            setSampleNBPromptVisibility(true);
        }

    }



    function Approver_MessageClick(id){

    }

    function handleDragOver(e){
        e.preventDefault();
    }

    const universeStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorUniverse + "deg)"
    }; 

    const messageStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorMessage + "deg)"
    };

    const timingStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorTiming + "deg)"
    };

    const sampleDataStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorSample + "deg)"
    };

    const approversStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorApprovers + "deg)"
    };     
    const newApproverStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorNewApprover + "deg)"
    };
    const newSampleNBStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorNewSampleNB + "deg)"
    }
    const sampleNBStyle = {
        transition: "transform .5s",
        transform: "rotate(" + rotationFactorSampleNB + "deg)"
    }


    // Post new event data to API
    const postNewEvent = async (event_fields) => {
        let jsonData = event_fields;
        try {
            //setLoading(true);
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.post(
                `${config.reportapi.url}/api/v1/event/createEvent?company_id=${event_fields['company_id']}`,
                jsonData,
                headers
            );
            //console.log("NewEvent call to postNewEvent: ",res.data);
        } catch (err) {
            //setLoading(false);
            //console.log(err.response.data.message);
            if (err.response.status == 401) {
                //setErrorOpen(true);
                //setErrorMessage(`Message Failed: ${err.response.data.message}`);
                //dispatch({ type: "LOGOUT" });
            }
            if (err.response.status == 400) {
                //setErrorOpen(true);
                //setErrorMessage(`Message Failed: ${err.response.data.message}`);
            }
        }
    };


    function createSampleSMSBody(sms){

        setSMS(sms);
        
        var localSMS = sms;

        //console.log("check", sampleSMS);
        //console.log("check contents", sampleSMS.includes("[%URL%]"));

        if(localSMS.includes("[%OptOutMessage-Classic%]"))
        {
            localSMS = localSMS.replace("[%OptOutMessage-Classic%]", "Reply STOP to Unsubscribe");
        }

        if(localSMS.includes("[%Double Opt-In/Out%]"))
        {
            localSMS = localSMS.replace("[%Double Opt-In/Out%]", "YES=Enroll STOP=Stop Msgs");
        }

        if(localSMS.includes("[%OptOutMessage-Stop=End%]"))
        {
            localSMS = localSMS.replace("[%OptOutMessage-Stop=End%]", "Stop=End");
        }

        if(localSMS.includes("[%OptOutMessage-Stop2End%]"))
        {
            localSMS = localSMS.replace("[%OptOutMessage-Stop2End%]", "Stop2End");
        }

        if(localSMS.includes("[%OptOutMessage-StopToEnd%]"))
        {
            localSMS = localSMS.replace("[%OptOutMessage-StopToEnd%]", "StopToEnd");
        }

        // //MSG Info

        if(localSMS.includes("[%URL%]"))
        {
            console.log("check", "here");
            localSMS = localSMS.replace("[%URL%]", sampleURL);
        }  

        if(localSMS.includes("[%County%]"))
        {
            localSMS = localSMS.replace("[%County%]", sampleCounty);
        }          

        //Member Info
        if(localSMS.includes("[%MemberFirstName%]"))
        {
            localSMS = localSMS.replace("[%MemberFirstName%]", sampleFirstName);
        }  

        if(localSMS.includes("[%MemberLastName%]"))
        {
            localSMS = localSMS.replace("[%MemberLastName%]", sampleLastName);
        }

        if(localSMS.includes("[%MemberName%]"))
        {
            localSMS = localSMS.replace("[%MemberName%]", sampleFirstName + ' ' + sampleLastName);
        }

        //console.log("check", localSMS);

        setSampleSMS(localSMS);               

    }

    function applyRotationUniverse(){
        if(rotationFactorUniverse == 0)
        {
            setRotationFactorUniverse(180);
            setUniverseClickMessage("Click me to hide universe mapping details");
        }
        else
        {
            setRotationFactorUniverse(0);
            setUniverseClickMessage("Click me to show universe mapping details");
        }
    }

    function applyRotationMessage(){
        if(rotationFactorMessage == 0)
        {
            setRotationFactorMessage(180);
            setMsgClickMessage("Click me to hide message details");
        }
        else
        {
            setRotationFactorMessage(0);
            setMsgClickMessage("Click me to show message details");
        }
    }

    function applyRotationTimning(){
        if(rotationFactorTiming == 0)
        {
            setRotationFactorTiming(180);
            setTimingClickMessage("Click me to hide scheduling details");
        }
        else
        {
            setRotationFactorTiming(0);
            setTimingClickMessage("Click me to show scheduling details");
        }
    }

    function applyRotationSampleData(){
        if(rotationFactorSample == 0)
        {
            setRotationFactorSample(180);
            setSampleClickMessage("Click me to hide message sample data details");
        }
        else
        {
            setRotationFactorSample(0);
            setSampleClickMessage("Click me to show message sample data details");
        }       

    }  

    function applyRotationApprovers(){
        if(rotationFactorApprovers == 0)
        {
            setRotationFactorApprovers(180);
            setApproverClickMessage("Click me to hide the selected approvers for your message");
        }
        else
        {
            setRotationFactorApprovers(0);
            setApproverClickMessage("Click me to show the selected approvers for your message");
        }       

    }

    function applyRotationNewApprover(){
        if(rotationFactorNewApprover == 0)
        {
            setRotationFactorNewApprover(180);
            setNewApproverClickMessage("Click me to hide the New Approver process");
        }
        else
        {
            setRotationFactorNewApprover(0);
            setNewApproverClickMessage("Click me to create a New Approver");
        }       

    }  

    function applyRotationSampleNB(){
        if(rotationFactorSampleNB== 0)
        {
            setRotationFactorSampleNB(180);
            setSampleNBClickMessage("Click me to hide the non-branded samples for your message");
        }
        else
        {
            setRotationFactorSampleNB(0);
            setSampleNBClickMessage("Click me to show the non-branded samples for your message");
        }       

    }

    function applyRotationNewSampleNB(){
        if(rotationFactorNewSampleNB == 0)
        {
            setRotationFactorNewSampleNB(180);
            setNewSampleNBClickMessage("Click me to hide the New Non Branded Sample");
        }
        else
        {
            setRotationFactorNewSampleNB(0);
            setNewSampleNBClickMessage("Click me to create a New Non Branded Sample");
        }       

    }

    // Vars as expected by API call

    // event_type = request.json['event_type']
    // event_name = request.json['event_name']
    // campaign_id = request.json['campaign_id']
    // event_start = request.json['event_start']
    // event_message = request.json['event_message']
    // event_url = request.json['event_url']
    // event_time_start_gmt = request.json['event_time']
    // event_msg_type = request.json['event_msg_type']
    // messenger_service_id = request.json['messenger_service_id']
    // message_cost = request.json['message_cost']

    function handleSubmit(e) {
        e.preventDefault();

        console.log("handleSubmit: entered");

        let event_fields = {}

        event_fields['event_type'] = eventType;
        event_fields['event_name'] = eventName;
        event_fields['company_id'] = 16;
        event_fields['campaign_id'] = 1928;

        // DATETIME format = YYYY-MM-DD HH:MI:SS
        event_fields['event_start'] = eventDate;

        event_fields['event_message'] = sms;
        event_fields['event_url'] = mmsAssetURL;

        // TIMESTAMP format = YYYY-MM-DD HH:MI:SS
        event_fields['event_time_start_gmt'] = eventTime;

        event_fields['event_msg_type'] = messagePurposeID;
        event_fields['messenger_service_id'] = messengerServiceIDCust;
        event_fields['message_cost'] = costPerMessage;


        // campaign_fields['company_id'] = 16;
        // campaign_fields['owner'] = first_name + " " + last_name;

        // if (campaignName != '') {
        //     campaign_fields['campaign_name'] = campaignName;
        // }
        // if (stateID != '') {
        //     campaign_fields['state_id'] = stateID;
        // }
        // if (raceTypeID != '') {
        //     campaign_fields['racetype_id'] = raceTypeID;
        // }
        // if (preferredAreaCode != '') {
        //     campaign_fields['preferred_area_code'] = preferredAreaCode;
        // }

        console.log("handleSubmit: event_fields = ", event_fields);

        if (Object.keys(event_fields).length > 0) {
            postNewEvent(event_fields);
        }
    }

    const validateForm = () => {
        // if (eventName === '' ||
        //     messagePurposeID === 0 ||
        //     costPerMessage === 0 ||
        //     eventID === 0 ||
        //     projectCode === '' ||
        //     paidForByCompliant === false ||
        //     eventTime === '' ||
        //     eventDate === '' ||
        //     mmsAssetURL === '' ||
        //     sms === '' ||
        //     adminPhoneNumber === '' ||
        //     transmissionPhoneNumber === '' ||
        //     messengerServiceIDAdmin === '' ||
        //     messengerServiceIDCust === '' ||
        //     smsBasePrice === 0 ||
        //     mmsBasePrice === 0 ||
        //     addtlSegmentPrice === 0 ||
        //     preferredAreaCode === 0) {
        //     return false;
        // }
        return true;
    };

    // const handleNavigation = useCallback(
    //   e => {
    //     const window = e.currentTarget;
    //     if (y > window.scrollY) {
    //       console.log("scrolling up");
    //     } else if (y < window.scrollY) {
    //       console.log("scrolling down");
    //     }
    //     setY(window.scrollY);
    //   }, [y]
    // );

    // useEffect(() => {
    //   setY(window.scrollY);
    //   window.addEventListener("scroll", handleNavigation);

    //   return () => {
    //     window.removeEventListener("scroll", handleNavigation);
    //   };
    // }, [handleNavigation]);


    return (
        <div>
            
            <div style={{margin: "20px 0px 20px 30px"}}>
                <form onSubmit={handleSubmit}>
                    <div className="flex">

                        <div className="eventCreateDetailForm">
                            {/*}<br/><h6>Create Your Next Messaging Event</h6><br/>{*/}

                            <div className="eventCreateHeaderInformation">
                                <div className="grid">
                                    <div className="flex" style={{margin: "0px 0px 10px 0px"}}>
                                        <div className="stepEllipse mt-3">
                                            <label className="text-2xl font-bold" style={{margin: "0px 0px 0px 10px"}}>1.</label>
                                        </div>
                                        <div className="text-xl font-bold" style={{margin: "15px 0px 0px 10px"}}>EVENT - Enter the basic event information</div>
                                    </div>
                                    
                                    <label className="userEditTitle" htmlFor="eventName">Event Name:</label>
                                    <input className="input input-bordered input-md" type="text" id="eventName" name="eventName" value={eventName} onChange={e => setEventName(e.target.value)} placeholder={'Event Name'}/>
                                </div>
                                <br/>

                                <div className="flex">
                                    <div className="grid" style={{margin: "0px 40px 0px 0px"}}>
                                        <label className="userEditTitle" htmlFor="eventtype">Event Type:</label>
                                        <select id="eventtype" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{margin: "0px 250px 0px 0px"}}>
                                            <option selected>Select Event Type</option>
                                            <option value="1">SMS Bulk Message</option>
                                            <option value="2">Peer to Peer</option>
                                        </select>
                                    </div>

                                <div className="grid" style={{margin: "0px 40px 0px 0px"}}>
                                        <label className="userEditTitle" htmlFor="messagepurpose">Message Purpose:</label>
                                        <select id="messagepurpose" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600
                                                dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{margin: "0px 250px 0px 0px"}}>
                                            <option selected>Select Message Purpose</option>
                                            <option value="1">Attack</option>
                                            <option value="2">Closing Statement</option>
                                            <option value="3">Endorsement</option>
                                            <option value="4">Fundraising</option>
                                            <option value="5">Introduction</option>
                                            <option value="6">Survey</option>
                                        </select>
                                    </div>

                                </div>
                                <br/>

                            </div>


                            <div className="flex" style={{margin: "10px 10px 10px 0px"}}>

                                <div className="grid eventCreateFunctionTile" style={{background: "cornflowerblue"}}>
                                    <label className="eventCreateFunctionTileTitle">Message Targets</label>
                                    <label className="eventCreateFunctionTileCount">00000</label>
                                    {/*<label className="eventCreateFunctionTileInformation" onClick={() => setOpenDataMapperModal(true)}>Attach Target List</label>*/}
                                    
                                    {/*}<label className="eventCreateFunctionTileInformation" onClick={() => document.getElementById('attachTargets').showModal()}>Attach Target List</label>
                                    <dialog id="attachTargets" className="modal">
                                      <div className="modal-box w-[600px]">
                                        <form method="dialog">
                                          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                        </form>
                                        <DataMapper />
                                        <h3 className="font-bold text-lg">Hello!</h3>
                                        <p className="py-4">This is where the data mapper goes</p>
                                      </div>
                                    </dialog>{*/}
                                </div>
                                <div className="grid eventCreateFunctionTile" style={{background: "dodgerblue"}}>
                                    <label className="eventCreateFunctionTileTitle">Approvers/Status</label>
                                    <label className="eventCreateFunctionTileCount">00000</label>
                                    {/*<label className="eventCreateFunctionTileInformation">Attach Target List</label>*/}
                                </div>
                                <div className="grid eventCreateFunctionTile" style={{background: "darkblue"}}>
                                    <label className="eventCreateFunctionTileTitle" style={{color: "white"}}>Samples (maximum 10)</label>
                                    <label className="eventCreateFunctionTileCount">00000</label>
                                    {/*<label className="eventCreateFunctionTileInformation" style={{color: "lightblue"}}>Attach Target List</label>*/}
                                </div>

                            </div>

                            <div className="eventCreateTiming">
                                <div className="collapse border border-black">
                                    <input id="timingAccordion" type="checkbox" onClick={applyRotationUniverse}/>
                                    <div className="collapse-title flex">
                                        <div className="stepEllipse mt-3">
                                            <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px -7px"}}>2.</label>
                                        </div>
                                        <div className="collapse-title text-xl font-bold">UNIVERSE MAPPING - {universeClickMessage}</div>                                 
                                        <svg
                                            style={universeStyle} 
                                            xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                            strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>                                        
                                    </div>

                                    <div className="collapse-content">
                                        <DataMapper/>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="eventCreateTiming">
                                <div className="collapse border border-black">
                                    <input id="timingAccordion" type="checkbox" onClick={applyRotationTimning}/>
                                    <div className="collapse-title flex">
                                        <div className="stepEllipse mt-3">
                                            <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px -7px"}}>3.</label>
                                        </div>
                                        <div className="collapse-title text-xl font-bold">TIMING - {timingClickMessage}</div>                                 
                                        <svg
                                            style={timingStyle} 
                                            xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                            strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>                                        
                                    </div>

                                    <div className="collapse-content">
                                        <div className="flex">
                                            <div className="flex" style={{margin: "10px 0px 20px 20px"}}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                    <DesktopDatePicker
                                                                label="Event Date"
                                                                inputFormat="MM/DD/YYYY" //depends on date lib
                                                                value={eventDate}
                                                                onChange={setEventDate}
                                                                renderInput={(params) => {
                                                                  return <TextField {...params} />;
                                                                }}
                                                                views={["month", "day"]}
                                                                showDaysOutsideCurrentMonth //very useful
                                                                clearable //Typing seems to be missing for this
                                                              />

                                                    <div style={{margin: "0px 0px 20px 20px"}}>
                                                        <TimePicker
                                                                    label="Event Time"
                                                                    inputFormat="hh:mm a"
                                                                    value={eventTime}
                                                                    onChange={ setEventTime }
                                                                    renderInput={(params) => {
                                                                      return <TextField {...params} />;
                                                                    }}
                                                                    views={["hours", "minutes"]}
                                                                    clearable
                                                                />
                                                    </div>

                                                </LocalizationProvider>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="eventCreateTiming">
                                <div className="collapse border border-black group-open:rotate-180">
                                    <input id="messageDetailsAccordion" type="checkbox" onClick={applyRotationMessage} />
                                    <div className="collapse-title flex">
                                        <div className="stepEllipse mt-3">
                                            <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px -7px"}}>4.</label>
                                        </div>                                     
                                        <div className="collapse-title text-xl font-bold">MESSAGE - {msgClickMessage}</div>
                                        <svg
                                            style={messageStyle}
                                            id="test" xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                            strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>
                                    </div>

                                    <div className="collapse-content">
                                        <div className="flex">

                                            <div style={{margin: "0px 0px 20px 20px"}}>
                                                <div className="grid">
                                                    <label className="userEditTitle" htmlFor="mmsAssetURL">MMS Asset (png, gif, jpeg/jpg, mp4):</label>
                                                    <div className="flex">
                                                        <input className="input input-bordered input-md" style={{width: "800px"}} type="text" id="mmsAssetURL" name="mmsAssetURL" value={mmsAssetURL} 
                                                            onChange={e => setMMSAssetURL(e.target.value)} placeholder={'MMS URL'}/>
                                                        <button className="btn btn-outline ml-2" style={{width: "50px"}}>...</button>
                                                    </div>
                                                </div>
                                                <br/>

                                                <div className="grid">
                                                    <label className="userEditTitle" htmlFor="sms">SMS:</label>
                                                    <textarea className="textarea textarea-bordered textarea-md h-[350px]" type="text" id="sms" name="sms"
                                                        value={sms} onChange={e => createSampleSMSBody(e.target.value)} 
                                                        onMouseMove={handleOnMouseMove}
                                                        placeholder={'SMS'} onDrop={handleOnDrop} onDragOver={handleDragOver}/>
                                                </div>
                                                <div className="mt-5">
                                                    <label className="userEditTitle" htmlFor="smsWildCards">SMS Body Wildcards:</label>
                                                    <div className="flex">
                                                        <div className="grid">
                                                        {
                                                            optOutList&&

                                                            optOutList.map((item, index) => (
                                                              <div className="draggableItemOptOut mt-2"
                                                                onDragStart={(e) => handleOnDrag( e, item)}
                                                                draggable
                                                                key={index}>
                                                                  {item}
                                                              </div>
                                                              ))
                                                        }
                                                        </div>
                                                        <div className="grid" style={{height: "30px"}}>
                                                        {
                                                            msgList&&

                                                            msgList.map((item, index) => (
                                                              <div className="draggableItemMessage mt-2"
                                                                onDragStart={(e) => handleOnDrag( e, item)}
                                                                draggable
                                                                key={index}>
                                                                  {item}
                                                              </div>
                                                              ))
                                                        }
                                                        </div>              
                                                        <div className="grid" style={{height: "45px"}}>
                                                        {
                                                            memberList&&

                                                            memberList.map((item, index) => (
                                                              <div className="draggableItemMember mt-2"
                                                                onDragStart={(e) => handleOnDrag( e, item)}
                                                                draggable
                                                                key={index}>
                                                                  {item}
                                                              </div>
                                                              ))
                                                        }
                                                        </div>                      
                                                    </div>
                                                    
                                                    
                                                </div>


                                                <div className="eventCreateTiming" style={{margin: "20px 0px 0px 0px"}}>
                                                    <div className="collapse border border-black group-open:rotate-180">
                                                        <input id="messageDetailsAccordion" type="checkbox" onClick={applyRotationSampleData} />
                                                        <div className="collapse-title flex">
                                                            <div className="stepEllipse mt-3"  style={{width: "45px"}}  />
                                                            <label className="text-l font-bold" style={{margin: "18px 0px 0px -33px"}}>4.1.</label>                                  
                                                            <div className="collapse-title text-xl font-bold">MESSAGE SAMPLE DATA - {sampleClickMessage}</div>
                                                            <svg
                                                                style={sampleDataStyle}
                                                                id="test" xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                                                strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>
                                                        </div>

                                                        <div className="collapse-content">

                                                            {    //SAMPLE DATA SECTION
                                                                // const [sampleFirstname, setSampleFirstName] = useState('George');
                                                                // const [sampleLastName, setSampleLastName] = useState('Washington');
                                                                // const [sampleURL, setSampleURL] = useState('https://www.foo.com');
                                                                // const [sampleCounty, setSampleCounty] = useState('Fairfax');
                                                                // const [sampleUID, setSampleUID] = useState('DCBD8AAC-AE0B-BE34-8CF4-FBEFBD8E5111');
                                                            }

                                                            <div className="flex">
                                                                <div className="grid">
                                                                    <label className="userEditTitle" htmlFor="sampleFirstName">First Name:</label>
                                                                    <input className="input input-bordered input-md" style={{width: "277px", margin: "0px 10px 0px 0px"}} type="text" id="sampleFirstName" name="sampleFirstName" 
                                                                        value={sampleFirstName} onChange={e => setSampleFirstName(e.target.value)} placeholder={'Sample First Name'}/>
                                                                </div>

                                                                <div className="grid">
                                                                    <label className="userEditTitle" htmlFor="sampleLastName">Last Name:</label>
                                                                    <input className="input input-bordered input-md" style={{width: "277px", margin: "0px 10px 0px 0px"}} type="text" id="sampleLastName" name="sampleLastName" 
                                                                        value={sampleLastName} onChange={e => setSampleLastName(e.target.value)} placeholder={'Sample Last Name'}/>
                                                                </div>

                                                                <div className="grid">
                                                                    <label className="userEditTitle" htmlFor="sampleCounty">County:</label>
                                                                    <input className="input input-bordered input-md" style={{width: "277px", margin: "0px 0px 0px 0px"}} type="text" id="sampleCounty" name="sampleCounty" 
                                                                        value={sampleCounty} onChange={e => setSampleCounty(e.target.value)} placeholder={'Sample County'}/>
                                                                </div>                                                                
                                                            </div>

                                                            <div className="grid">
                                                                <label className="userEditTitle" htmlFor="sampleURL">URL:</label>
                                                                <input className="input input-bordered input-md" type="text" id="sampleURL" name="sampleURL" 
                                                                    value={sampleURL} onChange={e => setSampleURL(e.target.value)} placeholder={'Sample URL'}/>
                                                            </div>

                                                            {/*}    
                                                            <div className="grid">
                                                                <label className="userEditTitle" htmlFor="sampleUID">UID:</label>
                                                                <input className="input input-bordered input-md" style={{width: "300px"}} type="text" id="sampleUID" name="sampleUID" 
                                                                    value={sampleUID} onChange={e => setSampleUID(e.target.value)} placeholder={'Sample UID'}/>
                                                            </div>
                                                            {*/}                                                                                                                  
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[700px] w-[350px] shadow-xl ml-16 mt-10 mb-5">
                                                <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                                                <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                                                <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                                                <div className="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                                                <div className="rounded-[2rem] overflow-hidden w-[322px] h-[672px] bg-white">
                                                    <div className="w-[322px] h-[672px] pr-2 pt-2 pb-2 pr-2 bg-white eventResult_PhoneArtBoard">
                                                        {
                                                        mmsAssetURL.length > 0 ?
                                                        <div className="eventEdit_MMSBody">
                                                            {
                                                                mmsAssetURL.endsWith("mp4")
                                                                    ? browserName != "Firefox"
                                                                            ? (<ReactPlayer controls width="250px" height="200px" type="video/mp4"/>)
                                                                            : (<div>UNABLE TO DISPLAY VIDEO SAMPLE IN FIREFOX</div>)
                                                                    : (<img style={{width: "250px"}}/>)
                                                            }
                                                        </div>
                                                        :
                                                        <div/>
                                                        }
                                                        {
                                                            mmsAssetURL.length == 0
                                                                ? 
                                                                (
                                                                    <div className="chat chat-start" style={{margin: "30px 0px 0px 0px", width: "300px"}}>
                                                                        <div className="chat-bubble eventEdit_SMSBody">{sampleSMS}</div>
                                                                    </div>                                                                    
                                                                )
                                                                :
                                                                (
                                                                    <div className="chat chat-start" style={{margin: "10px 0px 0px 0px", width: "300px"}}>
                                                                        <div className="chat-bubble eventEdit_SMSBody">{sampleSMS}</div>
                                                                    </div> 
                                                                )
                                                        }

                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="eventCreateTiming">
                                <div className="collapse border border-black">
                                    <input id="approversAccordion" type="checkbox" onClick={applyRotationApprovers}/>
                                    <div className="collapse-title flex">
                                        <div className="stepEllipse mt-3">
                                            <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px -7px"}}>5.</label>
                                        </div>
                                        <div className="collapse-title text-xl font-bold">APPROVERS - {approverClickMessage}</div>                                 
                                        <svg
                                            style={approversStyle} 
                                            xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                            strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>                                        
                                    </div>

                                    <div className="collapse-content">

                                        <div className="flex">
                                            <div>
                                                <div className="eventEditApproverBounds grid">
                                                    <label className="userEditTitle" htmlFor="sampleLastName">Previous Approvers:</label>
                                                    <div className="grid">
                                                        {existingApprovers.map((approver) => (
                                                            <div className="eventEditApprovers" 
                                                                key={approver.id} 
                                                                onDragStart={(e) => handleApproverOnDrag( e, approver.id)}
                                                                draggable>{approver.ApproverName}</div>
                                                        ))}
                                                    </div>
                                                                                               
                                                </div>

                                                <div className="eventCreateTiming" style={{margin: "20px 0px 0px 0px", padding: "0px 20px 0px 0px", width: "500px"}}>
                                                    <div className="collapse border border-black group-open:rotate-180">
                                                        <input id="messageDetailsAccordion" type="checkbox" onClick={applyRotationNewApprover} />
                                                        <div className="collapse-title flex">
                                                            <div className="stepEllipse mt-3" style={{width: "50px"}} />
                                                            <label className="text-l font-bold" style={{margin: "18px 0px 0px -33px"}}>5.1.</label>                                  
                                                            <div className="collapse-title text-xl font-bold">NEW APPROVER - {newApproverClickMessage}</div>
                                                            <svg
                                                                style={newApproverStyle}
                                                                id="test" xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                                                strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>
                                                        </div>

                                                        <div className="collapse-content">

                                                            <div className= "grid">

                                                                    <div className="grid">
                                                                        <label className="userEditTitle" htmlFor="newApproverName">Approver Name:</label>
                                                                        <input className="input input-bordered input-md" style={{width: "445px", margin: "0px 10px 0px 0px"}} type="text" id="newApproverName" name="newApproverName" 
                                                                            value={newApproverName} onChange={e => setNewApproverName(e.target.value)} placeholder={'Approver Name'}/>
                                                                    </div>

                                                                    <div className="grid">
                                                                        <label className="userEditTitle" htmlFor="newApproverCellPhone">Cell Phone:</label>
                                                                        <input className="input input-bordered input-md" style={{width: "445px", margin: "0px 0px 0px 0px"}} type="text" id="newApproverCellPhone" name="newApproverCellPhone" 
                                                                            value={newApproverPhone} onChange={e => setNewApproverPhone(e.target.value)} placeholder={'Approver Cell Phone Number'}/>
                                                                    </div>                                                                

                                                            </div>

                                                            <button className="btn btn-outline bg-blue-200 mt-5 ml-[205px] w-60" onClick={() => createNewApprover()}>Create</button>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="eventEditApproversTarget"
                                                onDrop={handleApproverOnDrop} onDragOver={handleDragOver}>
                                                <div className="grid" style={{height: "25px"}}>
                                                    
 
                                                </div>

                                                {
                                                    approverPromptVisibility == true ?
                                                        (
                                                            <div className="appliedApproverTargetRectangle">
                                                                <div className="dropFileTitle mt-[80px]">drop approvers here</div>
                                                            </div>
                                                        )
                                                        :
                                                        (   <div>
                                                                <div className="flex">                                                                                                                
                                                                    <div className="mt-[7px] ml-[150px]">Approver Name</div>
                                                                    <div className="mt-[7px] ml-[95px]">Approver Phone</div>
                                                                    <div className="mt-[7px] ml-[95px]">Status</div>

                                                                </div>

                                                                <hr/>
                                                            </div>

                                                        )
                                                }
                                                

                                                <div className="grid" style={{height: "45px"}}>
                                                    {eventApprovers.map((approver) => (
                                                        <div className="appliedApprover flex" key={approver.id}>
                                                            <div className="approverButtons" style={{margin: "0px 0px 0px 10px"}}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#000000" 
                                                                    strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"/>
                                                                    <path d="M16 16l-4-4-4 4"/>
                                                                </svg>
                                                            </div>
                                                            <div className="approverButtons" style={{margin: "0px 0px 0px 10px"}} onClick={() => Approver_RemoveApproverClick(approver)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#ff0000" 
                                                                    strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline>
                                                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                    <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                                                                </svg>
                                                            </div>                                                            
                                                            <div className="mt-[4px] ml-[60px] appliedApproverName">{approver.ApproverName}</div>
                                                            <div className="mt-[4px] ml-[0px] appliedApproverName">{approver.ApproverPhone}</div>
                                                            <div className="mt-[4px] ml-[0px] appliedApproverName">{approver.ApprovalStatus}</div>

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>                                            
                                        </div>



                                    </div>
                                </div>
                            </div>








                            <div className="eventCreateTiming">
                                <div className="collapse border border-black">
                                    <input id="sampleAccordion" type="checkbox" onClick={applyRotationSampleNB}/>
                                    <div className="collapse-title flex">
                                        <div className="stepEllipse mt-3">
                                            <label className="collapse-title text-2xl font-bold" style={{margin: "0px 0px 0px -7px"}}>6.</label>
                                        </div>
                                        <div className="collapse-title text-xl font-bold">SAMPLES - {sampleNBClickMessage}</div>                                 
                                        <svg
                                            style={sampleNBStyle} 
                                            xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                            strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>                                        
                                    </div>

                                    <div className="collapse-content">

                                        <div className="flex">
                                            <div>
                                                <div className="eventEditApproverBounds grid">
                                                    <label className="userEditTitle" htmlFor="sampleLastName">Previous Samples:</label>
                                                    <div className="grid">
                                                        {existingSamples.map((samplesNB) => (
                                                            <div className="eventEditSampleNB" 
                                                                key={samplesNB.id} 
                                                                onDragStart={(e) => handleSampleOnDrag( e, samplesNB.id)}
                                                                draggable>{samplesNB.SampleName}</div>
                                                        ))}
                                                    </div>
                                                                                               
                                                </div>

                                                <div className="eventCreateTiming" style={{margin: "20px 0px 0px 0px", padding: "0px 20px 0px 0px", width: "500px"}}>
                                                    <div className="collapse border border-black group-open:rotate-180">
                                                        <input id="messageDetailsAccordion" type="checkbox" onClick={applyRotationNewSampleNB} />
                                                        <div className="collapse-title flex">
                                                            <div className="stepEllipse mt-3" style={{width: "50px"}} />
                                                            <label className="text-l font-bold" style={{margin: "18px 0px 0px -33px"}}>6.1.</label>                                  
                                                            <div className="collapse-title text-xl font-bold">NEW SAMPLE - {newSampleNBClickMessage}</div>
                                                            <svg
                                                                style={newSampleNBStyle}
                                                                id="test" xmlns="http://www.w3.org/2000/svg" width={45} height={45} viewBox="0 0 24 24" fill="none" stroke={"#000000"} strokeWidth="2"
                                                                strokeLinecap="round" strokeLinejoin="round"><path d="M7 13l5 5 5-5M7 6l5 5 5-5"/></svg>
                                                        </div>

                                                        <div className="collapse-content">

                                                            <div className= "grid">

                                                                    <div className="grid">
                                                                        <label className="userEditTitle" htmlFor="newSampleName">Sample Name:</label>
                                                                        <input className="input input-bordered input-md" style={{width: "445px", margin: "0px 10px 0px 0px"}} type="text" 
                                                                            id="newSampleName" name="newSampleName" 
                                                                            value={newSampleNBName} onChange={e => setNewSampleNBName(e.target.value)} placeholder={'Non Branded Sample Name'}/>
                                                                    </div>

                                                                    <div className="grid">
                                                                        <label className="userEditTitle" htmlFor="newSampleNBCellPhone">Cell Phone:</label>
                                                                        <input className="input input-bordered input-md" style={{width: "445px", margin: "0px 0px 0px 0px"}} type="text" id="newSampleNBCellPhone" 
                                                                            name="newSampleNBCellPhone" 
                                                                            value={newSampleNBPhone} onChange={e => setNewSampleNBPhone(e.target.value)} placeholder={'Non Branded Sample Cell Phone Number'}/>
                                                                    </div>                                                                

                                                            </div>

                                                            <button className="btn btn-outline bg-blue-200 mt-5 ml-[205px] w-60" onClick={() => createNewSampleNB()}>Create</button>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="eventEditSamplesTarget"
                                                onDrop={handleSampleNBOnDrop} onDragOver={handleDragOver}>
                                                <div className="grid" style={{height: "25px"}}>
                                                    
 
                                                </div>

                                                {
                                                    sampleNBPromptVisibility == true ?
                                                        (
                                                            <div className="appliedSampleTargetRectangle">
                                                                <div className="dropFileTitle mt-[80px]">drop non-branded sample recipients here</div>
                                                            </div>
                                                        )
                                                        :
                                                        (   <div>
                                                                <div className="flex">                                                                                                                
                                                                    <div className="mt-[7px] ml-[150px]">Sample Name</div>
                                                                    <div className="mt-[7px] ml-[100px]">Sample Phone</div>
                                                                    <div className="mt-[7px] ml-[98px]">Status</div>

                                                                </div>

                                                                <hr/>
                                                            </div>

                                                        )
                                                }
                                                

                                                <div className="grid" style={{height: "45px"}}>
                                                    {eventSamplesNB.map((sampleNB) => (
                                                        <div className="appliedSample flex" key={sampleNB.id}>
                                                            <div className="approverButtons" style={{margin: "0px 0px 0px 10px"}}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#000000" 
                                                                    strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path d="M21.2 15c.7-1.2 1-2.5.7-3.9-.6-2-2.4-3.5-4.4-3.5h-1.2c-.7-3-3.2-5.2-6.2-5.6-3-.3-5.9 1.3-7.3 4-1.2 2.5-1 6.5.5 8.8m8.7-1.6V21"/>
                                                                    <path d="M16 16l-4-4-4 4"/>
                                                                </svg>
                                                            </div>
                                                            <div className="approverButtons" style={{margin: "0px 0px 0px 10px"}} onClick={() => SampleNB_RemoveSampleNBClick(sampleNB)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#ff0000" 
                                                                    strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline>
                                                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                    <line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line>
                                                                </svg>
                                                            </div>                                                            
                                                            <div className="mt-[4px] ml-[60px] appliedSampleName">{sampleNB.SampleName}</div>
                                                            <div className="mt-[4px] ml-[0px] appliedSampleName">{sampleNB.SamplePhone}</div>
                                                            <div className="mt-[4px] ml-[0px] appliedSampleName">{sampleNB.SampleStatus}</div>

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>                                            
                                        </div>



                                    </div>
                                </div>
                            </div>

                            {/*}

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="eventDate">Event Date:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="eventDate" name="eventDate" value={eventDate} onChange={e => setEventDate(e.target.value)} placeholder={'Event Date'}/>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="eventTime">Event Time:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="eventTime" name="eventTime" value={eventTime} onChange={e => setEventTime(e.target.value)} placeholder={'Event Time'}/>
                            </div>
                            <br/>


                            <div className="grid" style={{margin: "20px 0px 0px 0px"}}>
                                <label className="userEditTitle" htmlFor="costPerMessage">Cost Per Message:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="costPerMessage" name="costPerMessage" value={costPerMessage} onChange={e => setCCostPerMessage(e.target.value)} placeholder={'Cost Per Message'}/>
                            </div>
                            <br/>
                            {*/}




                            {/*}
                            <div className="grid">
                                <label className="userEditTitle" htmlFor="messengerServiceIDCust">Cust Messenger Service:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="messengerServiceIDCust" name="messengerServiceIDCust" value={messengerServiceIDCust} onChange={e => setMessengerServiceIDCust(e.target.value)} placeholder={'Messenger Service ID Cust'}/>
                            </div>
                            <br/>
                            {*/}

                            <button className="btn btn-outline bg-blue-200 mt-5 w-60 ml-[1100px]" type="submit" disabled={!validateForm()}>Save</button>

                            {/*}
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>



                            <div className="grid">
                                <label className="userEditTitle" htmlFor="messagePurposeID">Message Purpose:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="messagePurposeID" name="messagePurposeID" value={messagePurposeID} onChange={e => setMessagePurposeID(e.target.value)} placeholder={'Message Purpose ID'}/>
                            </div>


                            <div className="grid">
                                <label className="userEditTitle" htmlFor="eventname">Event ID:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="eventID" name="eventID" value={eventID} onChange={e => setEventID(e.target.value)} placeholder={'Event ID'}/>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="eventname">Project Code:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="projectCode" name="projectCode" value={projectCode} onChange={e => setProjectCode(e.target.value)} placeholder={'Project Code'}/>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="eventname">Paid For by Compliant:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="paidForByCompliant" name="paidForByCompliant" value={paidForByCompliant} onChange={e => setPaidForByCompliant(e.target.value)} placeholder={'Paid For By Compliant'}/>
                            </div>
                            <br/>


                            <div className="grid">
                                <label className="userEditTitle" htmlFor="eventname">Admin Messenger Service:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="messengerServiceIDAdmin" name="messengerServiceIDAdmin" value={messengerServiceIDAdmin} onChange={e => setMessengerServiceIDAdmin(e.target.value)} placeholder={'Messenger Service ID Admin'}/>
                            </div>
                            <br/>



                            <div className="grid">
                                <label className="userEditTitle" htmlFor="eventname">Preferred Area Code</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="preferredAreaCode" name="preferredAreaCode" value={preferredAreaCode} onChange={e => setPreferredAreaCode(e.target.value)} placeholder={'Preferred Area Code'}/>
                            </div>
                            <br/>
                            {*/}
                        </div>

                        <div>
                            <div className="{fix ? eventCreateDetailsBehind : eventCreateDetailsBehind.fixed}">
                                <label className="eventEditCalloutTitle">Message Details</label>

                                {/*}
                                <div className="grid">
                                    <label className="userEditTitle">SMS Base Price:</label>
                                    <label className="eventEditCalloutInformation" id="smsBasePrice" name="smsBasePrice">{smsBasePrice}</label>
                                </div>


                                <div className="grid">
                                    <label className="userEditTitle">MMS Base Price:</label>
                                    <label className="eventEditCalloutInformation" id="mmsBasePrice" name="mmsBasePrice">{mmsBasePrice}</label>

                                </div>

                                <div className="grid">
                                    <label className="userEditTitle">Additional Segment Price:</label>
                                    <label className="eventEditCalloutInformation" id="addtlSegmentPrice" name="addtlSegmentPrice">{addtlSegmentPrice}</label>
                                </div>
                                {*/}
                                <hr className="eventEditCalloutHR" />
                                
                                <label className="eventEditCalloutSectionTitle">Message Length Information</label>

                                <div className="grid">
                                    <label className="userEditTitle">Character Count:</label>
                                    <label className="eventEditCalloutInformation" id="characterCount" name="smsCharacterCount">{sampleSMS.length}</label>
                                </div>

                                <div className="grid">
                                    <label className="userEditTitle">Segment Count:</label>
                                    <label className="eventEditCalloutInformation" id="segmentCount" name="smsSegmentCount">{Math.round(sampleSMS.length/160,0) + 1}</label>
                                </div>

                                <div className="grid">
                                    <label className="userEditTitle">Crystal Segment Count:</label>
                                    <label className="eventEditCalloutInformation" id="segmentCount" name="smsSegmentCount">
                                        {
                                            mmsAssetURL.length > 0 && mmsAssetURL.endsWith("mp4")
                                                ? 1
                                                : 0
                                        }
                                    </label>
                                </div>                                


                                <hr className="eventEditCalloutHR" />

                                <label className="eventEditCalloutSectionTitle">Price Per Message</label>


                                <div className="grid">
                                    <label className="userEditTitle">Crystal Message Processing:</label>
                                    <label className="eventEditCalloutInformation" id="crystalMessageProcessing" name="smsSegmentCount">
                                    { mmsAssetURL.length > 0
                                        ? (mmsAssetURL.endsWith(".mp4")
                                                ? .005
                                                : 0
                                          )
                                          :
                                          (
                                                0
                                          )
                                    }
                                    </label>
                                </div>

                                <div className="grid">
                                    <label className="userEditTitle">MSG Price:</label>
                                    <label className="eventEditCalloutInformation" id="smsSegmentCount" name="smsSegmentCount">
                                        { sampleSMS.length > 0
                                            ? (mmsAssetURL.length > 0
                                                ? (.08 + (Math.round(sms.length/160,0) * .0075))
                                                : (.06 + (Math.round(sms.length/160,0) * .0075))
                                                )
                                            :
                                              (
                                              (0)
                                              )
                                        }</label>
                                </div>


                                <hr className="eventEditCalloutHR" />

                                <label className="eventEditCalloutSectionTitle">Transmission Phone Numbers</label>

                                <div className="grid">
                                    <label className="userEditTitle">Admin Phone:</label>
                                    <label className="eventEditCalloutInformation" id="adminPhoneNumber" name="adminPhoneNumber">{adminPhoneNumber.length > 0 ? {adminPhoneNumber} : "Not provissioned yet"}</label>
                                </div>

                                <div className="grid">
                                    <label className="userEditTitle">Transmission Phone:</label>
                                    <label className="eventEditCalloutInformation" id="transmissionPhoneNumber" name="transmissionPhoneNumber">{transmissionPhoneNumber.length > 0 ? {transmissionPhoneNumber} : "Not provissioned yet"}</label>
                                </div>

                                <hr className="eventEditCalloutHR" />

                            </div>
                        </div>


                    </div>




                </form>
            </div>
            <DataMapperModal open={openDataMapperModal} onClose={() => setOpenDataMapperModal(false)}/>
        </div>

    );

};

export default NewEvent;
