import { useContext, useState, useEffect } from "react";

import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

export default function AFCAdminMenu() {
    const { jwt, dispatch, first_name, last_name } = useContext(AuthContext);
    const { company, setCompany } = useContext(CompanyContext);

    const [isMounted, setIsMounted] = useState(false);
    const [clients, setClients] = useState(0);
    var customer_id = 0;

    useEffect(() => {
        if (!isMounted && jwt) {
            setIsMounted(true);
            if (clients == 0) {
                getRecentCompanies();
            }
        }
    });

    const onChange = (event) => {
        //console.log("selected option  event.target = ",event.target);
        const value = parseFloat(event.target.value);
        const name = event.target.selectedOptions[0].label;
        setCompany({name: name, id: value});
    }

    // Get a list of companies with recent events
    const getRecentCompanies = async () => {
        let defaultId = 0;
        try {
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.get(
                `${config.reportapi.url}/api/v1/event/collectcurrentcustomeraccounts`,
                headers
            );
            //console.log("getRecentCompanies(): ", res.data);
            setClients(res.data.values);
            defaultId = res.data.values[0].AccountId;
        } catch (err) {
            console.log(err.response.data.message);
            if (err.response.status == 401) {
                dispatch({ type: "LOGOUT" });
            }
        }

        return defaultId;
    };

    return (
        <div className="flex items-center">
            <label className="mr-2" htmlFor="customerSelect">Customer:</label>
            <select id="customerSelect" onChange={(e) => { onChange(e); }}>
                <option value="default">Select a Client</option>
                {clients
                    ? clients.map((client) => {
                        return <option key={client.AccountID} value={client.AccountID}>{client.AccountName}</option>;
                    })
                    : null}
            </select>
        </div>
    );
}
