import { useContext, useState, useEffect } from "react";

import { AuthContext } from "../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import red from "@mui/material/colors/red";
import HomeIcon from "@mui/icons-material/Home";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import AFCAdminMenu from "./AFCAdminMenu";

import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";

const Topbar = ({displayAdmin}) => {
    const { jwt, dispatch, first_name, last_name } = useContext(AuthContext);

    // Account menu
    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    const accountOpen = Boolean(accountAnchorEl);
    const handleAccountClick = (event) => {
        setAccountAnchorEl(event.currentTarget);
    };
    const handleAccountClose = () => {
        setAccountAnchorEl(null);
    };

    const logoutClick = async (event) => {
        event.preventDefault();
        dispatch({ type: "LOGOUT" });
        handleAccountClose();
    };

    function AvatarNameFunction() {
        if (jwt != null) {
            // Build the Avatar value. First letter of both first and last name
            let nameBuilder = "";
            if (first_name.length > 0) {
                nameBuilder += first_name[0].toUpperCase();
            }
            if (last_name.length > 0) {
                nameBuilder += last_name[0].toUpperCase();
            }

            return nameBuilder;
        }

        return "";
    }

    return (
        <div className="w-full h-12 bg-navbar-bg shadow sticky top-0" style={{ zIndex: 1 }}>
            <div className="h-full flex px-5 py-0 items-center justify-between">
                {isMobile === false ? 
                <Link to="/" style={{margin: "0px 0px 0px 20px"}}>
                    <HomeIcon
                        className="mr-2 h-9 w-9 text-btnmain-text"
                        aria-hidden="true"
                    />
                </Link>
                :
                <Link to="/" style={{margin: "0px 0px 0px 0px"}}>
                    <HomeIcon
                        className="mr-2 h-9 w-9 text-btnmain-text"
                        aria-hidden="true"
                    />
                </Link>
                }
                {displayAdmin === true ?
                <div className="justify-between">
                    <AFCAdminMenu />
                </div>
                :
                <div />
                }
                <div className="flex items-center">
                    {/*
                    <div className="mr-2.5 relative cursor-pointer">
                        <NotificationsNoneIcon />
                        <span className="flex justify-center h-4 absolute right-0 text-white w-4 rounded-full top-n5 bg-red-900 text-xs">
                            2
                        </span>
                    </div>
                    <div className="mr-2.5 relative cursor-pointer">
                        <LanguageIcon />
                    </div>
                    <div className="mr-2.5 relative cursor-pointer">
                        <SettingsIcon />
                    </div>
                    */}

                    <Avatar
                        className="h-10 w-10 cursor-pointer rounded-full"
                        sx={{ bgcolor: red[500] }}
                        aria-controls={accountOpen ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={accountOpen ? "true" : undefined}
                        onClick={handleAccountClick}
                    >
                        <AvatarNameFunction />
                    </Avatar>
                    <ArrowDropDownIcon
                        className="ml-2 h-5 w-5 text-blue-900"
                        aria-controls={accountOpen ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={accountOpen ? "true" : undefined}
                        onClick={handleAccountClick}
                    />

                    <Menu
                        id="account-menu"
                        anchorEl={accountAnchorEl}
                        open={accountOpen}
                        onClose={handleAccountClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={handleAccountClose}>
                            <PersonIcon
                                className="mr-2 h-5 w-5 text-btnmain-text"
                                aria-hidden="true"
                            />
                            Profile
                        </MenuItem>
                        <MenuItem onClick={logoutClick}>
                            <LogoutIcon
                                className="mr-2 h-5 w-5 text-btnmain-text"
                                aria-hidden="true"
                            />
                            Logout
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    );
};

Topbar.propTypes = {
    displayAdmin: PropTypes.bool,
};

export default Topbar;