import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

export const EventReportContext = createContext();

export const EventReportContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);

    const [isLoaded, setIsLoaded] = useState(false);
    const [eventID, setEventID] = useState(0);

    const [company, setCompany] = useState(
    {
        id: 0,
        name: 'not available',
    });

    const [eventDetails, setEventDetails] = useState(
    {
        id: 0,
        name: 'event name',
        campaign: 'campaign',
        project_code: 'project code',
        msg_body: 'message body content',
        msg_body_formatted: 'msg body formatted',
        msg_mms: 'message mms url',
        tz_name: 'pacific',
        utc_start_time: '00:00:00',
        utc_adjustment: -8,
        dtz_string: '',
        MsgBody_Length: 0,
        MsgBody_SegmentCount: 0,
    });


    const [eventStats, setEventStats] = useState(
    {
        msg_sent_count: 0,
        msg_err_count: 0,
        msg_opt_out_count: 0,
        msg_perc_opt_out_count: 0,
        msg_success_count: 0,
        msg_perc_successful_count: 0,
        msg_member_cleaned_count: 0
    });


    useEffect(() => {
        if (isLoaded == false && eventID > 0) {
            getStats(eventDetails.id);
        }
    });

    useEffect(() => {
        getStats(eventID);
    }, [eventID]);

    // Set the proper date-timezone-string
    function createDTZString(utc_start_time, tz_name) {
        // UTC will only init with a list of date/time components
        // so, split date time string into components for UTC
        const tempDTArray = utc_start_time.split(' ');
        const tempDateArray = tempDTArray[0].split('-');
        const tempTimeArray = tempDTArray[1].split(':');

        // create date object with proper UTC time
        const dts = new Date(
            Date.UTC(tempDateArray[0], tempDateArray[1]-1, tempDateArray[2],
                tempTimeArray[0], tempTimeArray[1], tempTimeArray[2])
        );

        // determine the timezone we need
        const tzPrefix = tz_name.split(' ', 1);
        let tzString = "";
        if (tzPrefix[0] == "Eastern") {
            tzString = "America/New_York";
        } else if (tzPrefix[0] == "Central") {
            tzString = "America/Chicago";
        } else if (tzPrefix[0] == "Mountain") {
            tzString = "America/Denver";
        } else if (tzPrefix[0] == "Pacific") {
            tzString = "America/Los_Angeles";
        }
        // get date time string for proper timezone (still missing timezone string though)
        const properTimeString = dts.toLocaleString("en-US", { timeZone: tzString });

        // determine Standard or Daylight time
        const isDST = new Date().getTimezoneOffset() < 0;
        const correctTZName = tzPrefix + (isDST ? " Standard Time" : " Daylight Time");

        // add Day of Week, just for grins
        const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const dow = dts.getDay();

        // finally have our string!
        const completeDTZString = days[dow] + " " + properTimeString + " " + correctTZName;
        //console.log("completeDTZString = ", completeDTZString);

        return completeDTZString;
    }

    // Get stats data from API
    const getStats = async (eventID) => {
        if (eventID > 0) {
            //setStats(0);
            var tempAPIValues;
            try {
                const headers = { headers: { Authorization: "Bearer " + jwt } };
                const res = await axios.get(
                    `${config.reportapi.url}/api/v1/event/eventmessagecarrierstatusheader?correlation_id=${eventID}`,
                    headers
                );
                console.log("EventReportContext: getEventStatusHeader", res.data);
                tempAPIValues = res.data.values;
            } catch (err) {
                console.log(err.response.data.message);
                if (err.response.status == 401) {
                    dispatch({ type: "LOGOUT" });
                }
            }

            // only call this API once
            setIsLoaded(true);

            let thisEventDetails = {
                id: tempAPIValues[0].CorrelationID,
                name: tempAPIValues[0].EventName.replace(/-\d{3}$/,""),
                campaign: tempAPIValues[0].CampaignName,
                project_code: tempAPIValues[0].MsgProjectCode,
                msg_body: tempAPIValues[0].MsgBody,
                msg_body_formatted: tempAPIValues[0].msgBodyFormatted,
                msg_mms: tempAPIValues[0].MsgMMS,
                tz_name: tempAPIValues[0].TimeZoneName,
                utc_start_time: tempAPIValues[0].UTCStartTime,
                utc_adjustment: parseFloat(tempAPIValues[0].UTCAdjustment),
                dtz_string:'',
                status:'',
                MsgBody_Length: tempAPIValues[0].MsgBody_Length,
                MsgBody_SegmentCount: tempAPIValues[0].MsgBody_SegmentCount,
            };

            thisEventDetails.dtz_string = createDTZString(thisEventDetails.utc_start_time, thisEventDetails.tz_name);
            if(thisEventDetails.dtz_string != '')
            {
                thisEventDetails.status = "COMPLETE";
            }
            else
            {
                thisEventDetails.status = "IN PROCESS";
            }

            setEventDetails(thisEventDetails);

            let thisEventStats = {
                msg_sent_count: parseFloat(tempAPIValues[0].MemberCount),
                msg_err_count: parseFloat(tempAPIValues[0].Error),
                msg_opt_out_count: parseFloat(tempAPIValues[0].OptOutCount),
                msg_perc_opt_out_count: parseFloat(tempAPIValues[0].PercOptOut),
                msg_success_count: parseFloat(tempAPIValues[0].Successful),
                msg_perc_successful_count: parseFloat(tempAPIValues[0].PercSuccessful),
                msg_member_cleaned_count: parseFloat(tempAPIValues[0].membersCleaned),
            };

            setEventStats(thisEventStats);
        }
    };


    return (
        <EventReportContext.Provider value={{company: company, setCompany: setCompany,
                                             eventID: eventID, setEventID: setEventID,
                                             eventDetails: eventDetails, setEventDetails: setEventDetails,
                                             eventStats: eventStats, setEventStats: setEventStats,
                                            }}>
            {children}
        </EventReportContext.Provider>
    );
}
