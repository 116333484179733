import { useEffect, useRef, useState, useContext, forwardRef, useCallback } from "react";
import { AuthContext } from "../../context/AuthContext";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams } from "react-router-dom";

import { browserName, browserVersion } from "react-device-detect";

import { usePapaParse } from 'react-papaparse';

import { useDropzone } from 'react-dropzone';

// Datatables
import $ from "jquery";
import DataTables from "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.min.css";

$.Datatable = require("datatables.net");
$.DataTable = require('datatables.net-select');
$.DataTable = require('datatables.net-buttons');
$.DataTable = require('datatables.net-buttons-dt');
$.DataTable = require('datatables.net-buttons/js/buttons.html5');

const DataMapper = () => {
    const { jwt, dispatch } = useContext(AuthContext);

    const tableRef = useRef();
    const [isMounted, setIsMounted] = useState(false);
    const { readString } = usePapaParse();
    const fileTypes = ["CSV"];

    const [selectedFile, setSelectedFile] = useState(0);
    const [fileColumns, setFileColumns] = useState([]);

    const [uniqueID, setUniqueID] = useState('');
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [cell, setCell] = useState('');
    const [politicalParty, setPoliticalParty] = useState('');
    const [state, setState] = useState('');
    const [precinct, setPrecinct] = useState('');
    const [county, setCounty] = useState('');
    const [url, setURL] = useState('');

    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
            $(tableRef.current).DataTable({
                data: [],
                columns: [
                    { title: 'ID', width: '150px'},
                    { title: 'First Name' },
                    { title: 'Last Name' },
                    { title: 'Cell Phone' },
                    { title: 'Party' },
                    { title: 'State' },
                    { title: 'Precinct' },
                    { title: 'County' },
                    { title: 'URL' },
                ],
                order: [[0, 'Last Name', 'Cell Phone']],
                options: {
                    processing: true,
                }
                //order: [[2, 'desc'],[4, 'desc']],
            });
            //console.log("jwt: ", jwt);

            //getUserList();
        }
    });



    // const onFileChange = event => {
    //     setSelectedFile( event.target.files[0] );

    //     readString( event.target.files[0], {
    //         header: true,
    //         complete: (results) => {
    //             const row1 = results.data.splice(0, 1);
    //             const headers = Object.keys(row1[0]);
    //             console.log("headers: ", headers);
    //             setFileColumns(headers);
    //         }
    //     })


    // };

    const onDrop = useCallback((acceptedFiles:FileList) => {
        var f = acceptedFiles[0];
        setSelectedFile( f );

        console.log("selected file:", f);
        console.log("selected file 2:", f.name);
        console.log("selected file 3", f.name.substr(f.name.trim().length - 4));

        setUniqueID ('');
        setFName('');
        setLName('');
        setCell('');
        setPoliticalParty('');
        setState('');
        setPrecinct('');
        setCounty('');
        setURL('');

        setFileColumns([]);

        if(f.name.substr(f.name.trim().length - 4) === '.csv'){

            console.log("selected file 4", f.name.substr(f.name.trim().length - 4))

            readString( f , {
                        header: true,
                        complete: (results) => {
                            const row1 = results.data.splice(0, 1);
                            const headers = Object.keys(row1[0]);
                            //console.log("headers: ", headers);
                            setFileColumns(headers);
                        }
                    })
        }
        else{
            //TODO: NEED TO RAISE TOAST ALERT HERE

            setFileColumns([]);
        }
        
      }, [])
    
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    
    function handleChange(e){
        switch(e.target.name){
            case 'slUID':
                 setUniqueID(e.target.value);
                 break;
            case 'slFName':
                setFName(e.target.value);
                break;
            case 'slLName':
                setLName(e.target.value);
                break;
            case 'slCell':
                setCell(e.target.value);
                break;
            case 'slParty':
                setPoliticalParty(e.target.value);
                break;
            case 'slState':
                setState(e.target.value);
                break;
            case 'slPrecinct':
                setPrecinct(e.target.value);
                break;
            case 'slCounty':
                setCounty(e.target.value);
                break;
            case 'slURL':
                setURL(e.target.value);
                break;
        }

        
    }

    if(!open) return null;

    return (
        <div style={{margin: "0px 0px 10px 10px"}}>

            <div className="dropFileRectangle" {...getRootProps()}>
                {/*<div className="dropFileTitle">drop csv universe file here</div>*/}

                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <div className="dropFileTitle">Drop the files here ...</div> :
                      <div className="dropFileTitle">Drag and drop CSV files here, or click to select CSV files</div>
                  }
            </div>

            {
                fileColumns.length > 0 ?
                (
                    <div className="dataMapperFile" style={{padding: "10px 10px 10px 10px"}}>
                        <div className="flex mt-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24" fill="none" stroke="#000000" 
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/>
                                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/>
                            </svg>
                            <label className="datamapperFileName ml-[5px] mt-[15px]">{selectedFile.name}</label>
                        </div>
                    </div>
                )
                :
                (
                    <div/>
                )
            }


{/*            <div>
                <input type="file" onChange={onFileChange} />
            </div>*/}

            <div className="flex">
                <div style={{margin: "0px 0px 0px 10px", width: "565px"}}>
                    <br/><h6>Map your messaging universe data</h6><br/>
                </div>
                <div style={{margin: "0px 0px 0px 10px", width: "500px"}}>
                    <br/><h6>Sample data</h6><br/>
                </div>            
            </div>

            <div className="flex" style={{margin: "5px 0px 0px 0px"}}>

                <div className="bg-white" style={{padding: "20px 0px 20px 50px", width: "580px"}}>

                    <div className="grid" style={{margin: "0px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="uniqueIdentifier'">Unique ID:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slUID" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for Unique ID)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}

                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="uniqueidentifer" value={uniqueID} name="uniqueidentifer" placeholder={'Auto Unique Identifier'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>

                    <div className="grid" style={{margin: "-15px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="firstName">First Name:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slFName" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for First Name)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}
                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="firstname" value={fname} name="firstname" placeholder={'Auto First Name'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>

                    <div className="grid" style={{margin: "-15px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="lastName">Last Name:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slLName" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for Last Name)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}
                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="lastname" value={lname} name="lastname" placeholder={'Auto Last Name'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>

                    <div className="grid" style={{margin: "-15px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="cellPhone">Cell Phone Number:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slCell" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for Cell Phone Number)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}
                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="cellphone" value={cell} name="cellphone" placeholder={'Cell Phone Number'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>

                    <div className="grid" style={{margin: "-15px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="politicalParty">Political Party:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slParty" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for Political Party)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}
                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="politicalparty" value={politicalParty} name="politicalparty" placeholder={'Auto Political Party'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>

                     <div className="grid" style={{margin: "-15px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="state">State:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slState" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for State)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}
                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="state" value={state} name="state" placeholder={'Auto State'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>

                    <div className="grid" style={{margin: "-15px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="precinct">Precinct:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slPrecinct" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for Precinct)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}
                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="precinct" value={precinct} name="precinct" placeholder={'Auto Precinct'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>

                    <div className="grid" style={{margin: "-15px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="county">County:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slCounty" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for County)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}
                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="county" value={county} name="county" placeholder={'Auto County'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>

                    <div className="grid" style={{margin: "-15px 0px 0px -30px"}}>
                        <label className="userEditTitle" htmlFor="url">URL:</label>
                        <div className="flex">
                            <select className="select select-bordered w-[250px]" name="slURL" multiple={false} onChange={handleChange}>
                                <option value=''>(select column for URL)</option>
                              {fileColumns.map((column) => (
                                  <option key={column}>{column}</option>
                              ))}
                            </select>
                            <div style={{margin: "10px 0px 0px 0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#b9b9b9"
                                    strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3"/>
                                </svg>
                            </div>
                            <input className="input input-bordered input-md w-[250px]" type="text" id="url" value={url} name="url" placeholder={'Auto URL'} readOnly={true}/>
                        </div>
                    </div>
                    <br/>
                </div>

                <div className="bg-blue-50" style={{width: "750px"}}>
                    <div className="DataOverflowScroll" style={{margin: "20px 20px 0px 20px", height: "96%"}}>
                        <table ref={tableRef} className="compact hover" style={{width: "1500px", cursor: "pointer"}}></table>
                    </div>
                </div>

            </div>
        </div>
    );

};

export default DataMapper;
