import { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";

// API calls
import axios from "axios";
import { config } from "../constants/global.js";

export const CompanyContext = createContext();

export const CompanyContextProvider = ({ children }: any) => {
    const { jwt, dispatch } = useContext(AuthContext);

    const [company, setCompany] = useState(
    {
        name: 'not available',
        id: 0
    });

    useEffect(() => {
        if (company.id > 0) {
            getStats(company.id);
        }
    }, [company]);

    const [stats, setStats] = useState(0);

    // Get stats data from API
    const getStats = async (companyID) => {
        setStats(0);
        try {
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.get(
                `${config.reportapi.url}/api/v1/event/collectAmplifyKeyStatsPerAccount?dayspan=7&account_id=${companyID}`,
                headers
            );

            console.log("KeyStats API get call: ",res.data);
            console.log("KeyStats length: ",res.data.values.length);
            if (res.data.values.length > 0) {
                setStats(res.data.values[0]);
            }
        } catch (err) {
            console.log(err.response.data.message);
            if (err.response.status == 401) {
                dispatch({ type: "LOGOUT" });
            }
        }
    };

    return (
        <CompanyContext.Provider value={{company: company, setCompany: setCompany, stats: stats}}>
            {children}
        </CompanyContext.Provider>
    );
}
