import { useEffect, useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { CompanyContext } from "../../context/CompanyContext";
import Login from "../login/Login";
import { Link } from "react-router-dom";

import Topbar from "../../components/topbar/Topbar";

//campaign management
import NewCampaign from "../../components/campaign/NewCampaign";

// Notifications
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function CampaignCreate() {
	// Validate authentication
	const { jwt } = useContext(AuthContext);
	if (jwt === null) {
		return <Login fullscreen={true} />;
	}

	//const [totMemberCountFormatted, setTotalSent] = useState(0);

	useEffect(() => {
		
	}, []);

	useEffect(() => {
		document.title = "Alliance Forge | New Campaign";
	//console.log("JWT = ", jwt);
	}, [jwt]);

	return (
		<div>
			<div className="accountHeader">
                <div className="adminHeaderName flex">
                    <img className="adminHeaderImage" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Flame_Icon_ShapeFile_BW.png" />

                    <div className="adminHeaderTitle">Campaign</div>
                    <div className="adminHeaderTitle2">Management</div>
                </div>
                <div className="adminHeaderDescription">create campaign</div>
            </div>

            <Topbar />

            <div className="mainBody">
				<NewCampaign />

            </div>

		</div>

	);

}