import Admin from "./pages/admin/Admin";
import UserEdit from "./pages/admin/UserEdit";
import CampaignCreate from "./pages/campaign/campaignCreate"
import EventCreate from "./pages/event/EventCreate"
//import { EventReportContextProvider } from "./context/EventReportContext";

import { React, useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import { config } from "./constants/global.js";

function App() {

    const { jwt, dispatch, refresh } = useContext(AuthContext);

    const reload = async () => {
        console.log("Reload JWT");

        // Set JWT header
        const headers = { headers: { Authorization: "Bearer " + jwt } };

        // Call refresh API
        try {
            const res = await axios.get(
                `${config.accountapi.url}/api/v1/auth/refresh`,
                headers
            );

            // Set new JWT
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
        } catch (err) {
            console.log(err);
        }
    }

    if (refresh) {
        reload();
    }

    return (
        <Router>
            <div className="flex flex-no-wrap h-full">
                <div className="w-full overflow-auto bg-gray-300">
                    <div className="w-full mx-auto">
                        <Routes>
                            <Route path="/" element={<Admin />} />
                            <Route path="/useredit" element={<UserEdit />} />
                            <Route path="/campaigncreate" element={<CampaignCreate />} />
                            <Route path="/eventcreate" element={<EventCreate />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
