import { createContext, useReducer, useEffect } from "react";
import jwt_decode from "jwt-decode";

const DEV_JWT_ENV = process.env.NODE_ENV === "development" ? process.env.REACT_APP_JWT : null;
const DEV_JSON = (DEV_JWT_ENV != undefined && DEV_JWT_ENV != null) ? JSON.parse(DEV_JWT_ENV) : null

const INITIAL_STATE = {
    jwt: JSON.parse(localStorage.getItem("jwt")) || DEV_JSON || null,
    loading: false,
    refresh: false,
    error: null,
};

export const AuthContext = createContext(INITIAL_STATE);

const AuthReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN_START":
            return {
                jwt: null,
                loading: true,
                refresh: false,
                error: null,
            };
        case "LOGIN_SUCCESS":
            return {
                jwt: action.payload,
                loading: false,
                refresh: false,
                error: null,
            };
        case "LOGIN_FAILURE":
            return {
                jwt: null,
                loading: false,
                refresh: false,
                error: action.payload,
            };
        case "LOGOUT":
            return {
                jwt: null,
                loading: false,
                refresh: false,
                error: null,
            };
        case "CHECK_AUTH":
            return checkAuth(state);
        default:
            return state;
    }
};

function checkAuth(state) {
    let state_jwt = state.jwt;

    // Check if JWT if null
    if (state_jwt == null) {
        return {
            jwt: null,
            loading: false,
            refresh: false,
            error: null,
        }
    }

    // Check if JWT string is in object
    if (!("jwt" in state_jwt)) {
        return {
            jwt: null,
            loading: false,
            refresh: false,
            error: null,
        }
    }

    // Get the JWT decoded to dictionary
    let jwt_string = state_jwt["jwt"];
    const jwtPayload = jwt_decode(jwt_string);

    // First thing to do is check that the JWT is valid
    if ("exp" in jwtPayload) {
        // Get the current timestamp. This is millisecond.
        let current_epoch = Date.now();

        // Get the EXP from JWT. This will be in seconds, so convet to milliseconds.
        let jwt_epoch = jwtPayload.exp * 1000;

        // If the expiration is valid set the values
        if (jwt_epoch > current_epoch) {
            // Check if JWT should be reloaded
            // Do it if less than 10 minutes is left
            let minute_diff_check = 10;
            let reload_time = current_epoch + (minute_diff_check * 60 * 1000);
            if (jwt_epoch < reload_time) {
                return {
                    jwt: state.jwt,
                    loading: false,
                    refresh: true,
                    error: null,
                }
            }

            return {
                jwt: state.jwt,
                loading: false,
                refresh: false,
                error: null,
            }
        }
    }

    // Default is invalid JWT
    return {
        jwt: null,
        loading: false,
        refresh: false,
        error: null,
    }
}


export const AuthContextProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

    useEffect(() => {
        console.log("Setting JWT");
        localStorage.setItem("jwt", JSON.stringify(state.jwt));
    }, [state.jwt]);

    let first_name = "";
    let last_name = "";
    let business_key = null;
    let roles = [];
    let jwt_string = null;

    // Run the auth check of the JWT
    const jwtCheck = checkAuth(state);

    if (jwtCheck.jwt != null) {
        jwt_string = jwtCheck.jwt["jwt"];
        const jwtPayload = jwt_decode(jwt_string);

        if ("first_name" in jwtPayload) {
            first_name = jwtPayload.first_name;
        }
        if ("last_name" in jwtPayload) {
            last_name = jwtPayload.last_name;
        }
        if ("business_key" in jwtPayload) {
            business_key = jwtPayload.business_key;
        }
        if ("roles" in jwtPayload) {
            roles = jwtPayload.roles;
        }
    } else {
        state.jwt = null;
        first_name = "";
        last_name = "";
        business_key = null;
        roles = [];
        jwt_string = null;
    }

    return (
        <AuthContext.Provider
            value={{
                jwt: jwt_string,
                first_name: first_name,
                last_name: last_name,
                business_key: business_key,
                roles: roles,
                loading: state.loading,
                refresh: state.refresh,
                error: state.error,
                dispatch,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
