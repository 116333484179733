import { useEffect, useRef, useState, useContext, forwardRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams } from "react-router-dom";

// API calls
import axios from "axios";
import { config } from "../../constants/global.js";

// Notifications
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Dual List Box
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DualListBox from "react-dual-listbox";
import 'react-dual-listbox/lib/react-dual-listbox.css';

const AdminUserEdit = ({ user_key }) => {
    const { jwt, dispatch } = useContext(AuthContext);

    const securityRef = useRef();
    const [isMounted, setIsMounted] = useState(false);
    const [userDetails, setUserDetails] = useState(0);
    const [loading, setLoading] = useState(false);

    // New user
    const [createNew, setCreateNew] = useState(false);
    const [newUsername, setNewUsername] = useState('');

    // User Form Fields
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [status, setStatus] = useState('');
    const [security, setSecurity] = useState('');
    const [password, setPassword] = useState('');
    const [password_verification, setPassword_Verification] = useState('');

    const [companyList, setCompanyList] = useState([]);
    const [userCompanyList, setUserCompanyList] = useState([]);

    const navigate = useNavigate();

    // Notification vars & functions (called a 'snackbar' or 'toast')
    const Alert = forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const vertical = 'bottom';
    const horizontal = 'right';
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessOpen(false);
        setSuccessMessage('');
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
        setErrorMessage('');
    };

    const handleWarningClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setWarningOpen(false);
        setWarningMessage('');
    };


    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
            getCompanyList();
            if (user_key != 'new') {
                getUserDetails(user_key);
            } else {
                setCreateNew(true);
                createSecurityList('companyuser');
           }
        }
    });

    // Called anytime userList is modified
    useEffect(() => {
        if (userDetails != 0) {
            //console.log("userDetails = ", userDetails);
            createSecurityList(userDetails.roleDetails.security);
        }
    }, [userDetails]);

    function unpackRoles(packedRoles) {
        let roleDetails = {'security':0, 'company_id':0, 'webpages':[]};
        if (packedRoles !== undefined) {
            for (let i = 0; i < packedRoles.length; i++) {
                let tArray = packedRoles[i].split(':', 2);
                switch(tArray[0]) {
                    case 'sec':
                        roleDetails['security'] = tArray[1];
                        break;
                    case 'company':
                        roleDetails['company_id'] = tArray[1];
                        break;
                    case 'view':
                        roleDetails['webpages'].push( tArray[1] );
                        break;
                    default:
                        roleDetails[tArray[0]] = tArray[1];
                }
            }
        }
        return roleDetails;
    }

    // Get list of companies from API
    const getCompanyList = async () => {
        var tempList = 0;
        var tempCompanyList = [];
        try {
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.get(
                `${config.reportapi.url}/api/v1/event/collectCompanyInfo?company_id=-626`,
               headers
            );

            // returns a list of:
            // {
            //     AccountID: 68,
            //     AccountName: "A Shor CA SD14",
            //     logoURL: "https://blahblahblah"
            // }
            tempList = res.data.values;
            console.log("AdminUserEdit - getCompanyList() res.data = ", res.data);

        } catch (err) {
            console.log(err.response.data.message);
            if (err.response.status == 401) {
                dispatch({ type: "LOGOUT" });
            }
        }

        for (let i=0; i < tempList.length; i++) {
            tempCompanyList.push({value: tempList[i]['AccountID'], label: tempList[i]['AccountName']});
        }
        setCompanyList(tempCompanyList);
        console.log("Final companyList = ", companyList);
    };

    // Get user data from API
    const getUserDetails = async (user_key) => {
        var userDetails = 0
        try {
            setLoading(true);
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.get(
                `${config.accountapi.url}/api/v1/users/${user_key}`,
                headers
            );

            userDetails = res.data;
        } catch (err) {
            console.log(err.response.data.message);
            if (err.response.status == 401) {
                dispatch({ type: "LOGOUT" });
            }
        }

        // probably need functions to unpack and pack "roles" for consistency
        //console.log("roleDetails = ", roleDetails);
        let thisRoleDetails = unpackRoles(userDetails.roles);
        userDetails['roleDetails'] = thisRoleDetails;
        setUserDetails(userDetails);
        if ('company_id' in thisRoleDetails) {
            let stringArray = thisRoleDetails['company_id'];
            if (stringArray != '') {
                let numArray = stringArray.split(",");
                for (let i=0; i<numArray.length; i++) {
                    numArray[i] = Number(numArray[i]);
                }
                setUserCompanyList(numArray);
            }
        }

        setLoading(false);
    };

    // Patch user data to API
    const patchUserDetails = async (user_key, user_fields) => {
        let jsonData = user_fields;
        var userDetails = 0
        try {
            setLoading(true);
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.patch(
                `${config.accountapi.url}/api/v1/users/${user_key}`,
                jsonData,
                headers
            );

            userDetails = res.data;
            setSuccessOpen(true);
            setSuccessMessage(`User Account Updated`);
            navigate("/");

        } catch (err) {
            setLoading(false);
            console.log(err.response.data.message);
            if (err.response.status == 401) {
                setErrorOpen(true);
                setErrorMessage(`Message Failed: ${err.response.data.message}`);
                dispatch({ type: "LOGOUT" });
            }
            if (err.response.status == 400) {
                setErrorOpen(true);
                setErrorMessage(`Message Failed: ${err.response.data.message}`);
            }
        }

        // probably need functions to unpack and pack "roles" for consistency
        //console.log("roleDetails = ", roleDetails);
        let thisRoleDetails = unpackRoles(userDetails.roles);
        userDetails['roleDetails'] = thisRoleDetails;
        setUserDetails(userDetails);
        setLoading(false);
    };


    function handleSubmit(e) {
        e.preventDefault();

        console.log("handleSubmit: entered");

        let user_fields = {}

        if (firstname != '') {
            user_fields['first_name'] = firstname;
        }
        if (lastname != '') {
            user_fields['last_name'] = lastname;
        }
        if (email != '') {
            user_fields['email'] = email;
        }
        if (phone != '') {
            user_fields['phone'] = phone;
        }
        if (status != '') {
            user_fields['status'] = status;
        }
        if (password != '') {
            user_fields['password'] = password;
        }
        if (password_verification != '') {
            user_fields['password_verification'] = password_verification;
        }

        if (security != '' || userCompanyList.length > 0) {
            // need to rebuild the "roles" string for the user
            let secString = '';
            if (security != '') {
                secString = "sec:"+security;
            } else {
                if (userDetails != 0 && 'roleDetails' in userDetails && 'security' in userDetails['roleDetails']) {
                    secString = "sec:"+userDetails['roleDetails']['security'];
                } else {
                    secString = 'sec:companyuser';
                }
            }

            let companyString = "company:0";
            if (userCompanyList.length > 0) {
                companyString = "company:"+userCompanyList.toString();
            } else {
                if (userDetails != 0 && 'roleDetails' in userDetails && 'company_id' in userDetails['roleDetails']) {
                    companyString = "company:"+userDetails['roleDetails']['company_id'];
                }
            }

            user_fields['roles'] = [secString, companyString];
        }

        console.log("user_fields = ", user_fields);

        if (Object.keys(user_fields).length > 0) {
            if(user_key != 'new')
            {
                user_fields['key'] = user_key;
                patchUserDetails(user_key, user_fields);
            } else {
                user_fields['user_name'] = newUsername;
                postNewUser(user_fields);
            }

        }
    }

    // Post new user data to API
    const postNewUser = async (user_fields) => {
        let jsonData = user_fields;
        var userDetails = 0
        try {
            setLoading(true);
            const headers = { headers: { Authorization: "Bearer " + jwt } };
            const res = await axios.post(
                `${config.accountapi.url}/api/v1/users`,
                jsonData,
                headers
            );
            //console.log("AdminUserEdit call to postNewUser: ",res.data);

            user_key = res.data.key;
            userDetails = res.data;
            setSuccessOpen(true);
            setSuccessMessage(`User Account Created`);
            navigate("/");
        } catch (err) {
            setLoading(false);
            console.log(err.response.data.message);
            if (err.response.status == 401) {
                setErrorOpen(true);
                setErrorMessage(`Message Failed: ${err.response.data.message}`);
                dispatch({ type: "LOGOUT" });
            }
            if (err.response.status == 400) {
                setErrorOpen(true);
                setErrorMessage(`Message Failed: ${err.response.data.message}`);
            }

        }
    };

    // Roles string = "sec:designer,company:25,view:message,view:voter,view:event,view:upload"
    //   Security Level = sec:designer
    //   Company Access = company:25
    //   Web Page Access = view:message, view:voter, view:event, view:upload

    function createSecurityList(selectedSecurity) {
        let selected = false;
        let securityValues = ['companyadmin','companyuser','afcadmin','afcuser','afcdeveloper','afcdesigner','globaladmin'];
        let securityOptions = [];
        securityRef.current.options.length = 0;
        for (let i = 0; i < securityValues.length; i++) {
            let option = document.createElement('option');
            option.value = securityValues[i];
            option.textContent = securityValues[i];
            if (selectedSecurity == securityValues[i]) {
                option.selected = true;
                selected = true;
            }
            securityRef.current.appendChild(option);
        }
        if (selected == false) {
            let option = document.createElement('option');
            option.value = selectedSecurity;
            option.textContent = selectedSecurity;
            option.selected = true;
            securityRef.current.appendChild(option);
        }
    }

    return (
        <>
            <link rel="stylesheet" href="//use.fontawesome.com/releases/v5.6.3/css/all.css"/>
            <div className="container">
                <div className="w-full grid items-center pl-5 pr-5 pt-5 pb-5">
                    <div style={{width: "1200px"}}>
                        <form onSubmit={handleSubmit}>
                            { user_key === "new" ? (
                                <div >
                                    <div className="grid">
                                        <label className="userEditTitle" htmlFor="fname">User Login:</label>
                                        <input className="input input-bordered input-md w-full max-w-xs" type="text" id="newusername" name="newusername" value={newUsername} onChange={e => setNewUsername(e.target.value)} />
                                    </div>
                                    <hr style={{height: "3px", backgroundColor: "black", margin: "20px 00px 20px 0px"}}/>
                                </div>
                            ) : (<></>) }

                            <div className="flex">
                                { user_key != "new" ? (
                                    <div>
                                        <div className="userEditTitle" >user:</div>
                                        <div className="userEditBody" style={{width: "500px"}}>{userDetails.user_name}</div>
                                    </div>
                                ) : (<></>)}
                                { user_key != "new" ? (
                                    <div style={{margin: "0px 0px 0px 30px", width: "500px"}}>
                                        <div className="userEditTitle" >user id:</div>
                                        <div className="userEditBody" >{userDetails.key}</div>
                                    </div>
                                ) : (<></>)}


                            </div>

                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="fname">First name:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="firstname" name="firstname" value={firstname} onChange={e => setFirstname(e.target.value)} placeholder={userDetails.first_name}/>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="lname">Last name:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="lastname" name="lastname" value={lastname} onChange={e => setLastname(e.target.value)} placeholder={userDetails.last_name}/>
                            </div>
                            <br/>

                            <div className="flex">
                                <div className="grid">
                                    <label className="userEditTitle" htmlFor="password">Password:</label>
                                    <input className="input input-bordered input-md w-full max-w-xs" type="password" id="password" name="password" value={password} onChange={e => setPassword(e.target.value)}/>
                                </div>
                                <br/>

                                <div className="grid" style={{margin: "0px 0px 0px 20px"}}>
                                    <label className="userEditTitle" htmlFor="passwordverify">Password:</label>
                                    <input className="input input-bordered input-md w-full max-w-xs" type="password" id="passwordverification" name="passwordverification" value={password_verification} onChange={e => setPassword_Verification(e.target.value)}/>
                                </div>
                            </div>
                            <div style={{color: "red", fontSize: "10px"}}>Password must be 8 charcaters minimum, and contain at least one digit, one uppercase letter, one lower case letter, and one special character (!@#$%^&)</div>

                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="email">Email:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="email" name="email" value={email} onChange={e => setEmail(e.target.value)} placeholder={userDetails.email}/>
                            </div>
                            <br/>


                            <div className="grid">
                                <label className="userEditTitle" htmlFor="phone">Phone:</label>
                                <input className="input input-bordered input-md w-full max-w-xs" type="text" id="phone" name="phone" value={phone} onChange={e => setPhone(e.target.value)} placeholder={userDetails.phone}/>
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="status">Status:</label>
                                <select className="select select-bordered select-md w-full max-w-xs" id="status" name="status" size="1" onChange={e => setStatus(e.target.value)}>
                                    <option value="active">Active</option>
                                    <option value="archived">Archived</option>
                                    <option value="inactive">Inactive</option>
                                    <option value="locked">Locked</option>
                                    <option value="suspended">Suspended</option>
                                </select>
                            </div>
                            <br/>

                            <br/><h6>Roles</h6><br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="company">Company ID:</label>
                                <DualListBox
                                    showHeaderLabels={true}
                                    canFilter
                                    options={companyList}
                                    selected={userCompanyList}
                                    onChange={(value) => setUserCompanyList(value)}
                                />
                            </div>
                            <br/>

                            <div className="grid">
                                <label className="userEditTitle" htmlFor="security role">Security:</label>
                                <select className="select select-bordered select-md w-full max-w-xs" id="security" name="security" ref={securityRef} onChange={e => setSecurity(e.target.value)}/>
                            </div>

                            <br/>
                            {/*}
                            <label htmlFor="pageaccess">Web Page Access:</label><br/>
                                <select id="pageaccess" name="pageaccess" size="4" multiple>
                                    <option value="dashboard">Dashboard</option>
                                    <option value="eventreport">Event Report</option>
                                    <option value="companyadmin">Company Admin</option>
                                </select>
                            <br/>
                            {*/}
                            <button className="btn btn-outline mt-5 w-60" type="submit">Submit</button>
                        </form>
                    </div>
                    <div className="absolute w-48 -h-48">
                        { loading ? (
                                <div role="status">
                                    <svg aria-hidden="true" className="w-48 h-48 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                        ) : (<></>) }
                    </div>

                    <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleSuccessClose} anchorOrigin={{ vertical, horizontal: "center" }}>
                      <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                      </Alert>
                    </Snackbar>
                    <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical, horizontal: "center" }}>
                      <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                    <Snackbar open={warningOpen} autoHideDuration={6000} onClose={handleWarningClose} anchorOrigin={{ vertical, horizontal: "center" }}>
                      <Alert onClose={handleWarningClose} severity="warning" sx={{ width: '100%' }}>
                        {warningMessage}
                      </Alert>
                    </Snackbar>

                </div>
            </div>
        </>
    );
};

AdminUserEdit.propTypes = {
    user_key: PropTypes.string.isRequired
};

export default AdminUserEdit;
