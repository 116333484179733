import { useState, useEffect, useContext, forwardRef } from "react";
import PropTypes from 'prop-types';
import { AuthContext } from "../../context/AuthContext";
import { config } from "../../constants/global.js";
import axios from "axios";
import { isMobile } from "react-device-detect";

import ReactPlayer from 'react-player';

// Notifications
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function Login({fullscreen=true}) {
    /**
     * useEffect Is called once the page loads.
     * - set title of page information
     * - Initial call to get the messages for the DataGrid
     */
    useEffect(() => {
        document.title = "Alliance Forge | Login";
    }, []);

    // Load props
    // const {fullscreen = false} = this.props;

    // Notification vars & functions (called a 'snackbar' or 'toast')
    const Alert = forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [errorOpen, setErrorOpen] = useState(false);
    const vertical = 'bottom';
    const horizontal = 'middle';
    const [errorMessage, setErrorMessage] = useState('');

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorOpen(false);
        setErrorMessage('');
    };


    const { dispatch } = useContext(AuthContext);

    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const loginClick = async (event) => {
        event.preventDefault();

        dispatch({ type: "LOGIN_START" });
        try {
            const credentials = {
                user_name: username,
                password: password,
            };
            const res = await axios.post(
                `${config.accountapi.url}/api/v1/auth/authenticate`,
                credentials
            );
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
        } catch (err) {
            console.log(err);
            dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
            setErrorOpen(true);
            setErrorMessage(`Login Failed`);
        }
    };

    var af_logo = "/images/alliance_forge_logo_red.png";

    var topMargin = "200px 0"

    if(isMobile === true)
    {
        topMargin = "30px 0";
    }

    return (
        <div className="signinBack ml-0 mr-0">

            <div>

                {/*}
                <div>
                    <ReactPlayer className="signinVideoBack" playing={true} loop={true} muted={true} height="2000px" width="2000px"
                        url="https://afcuploadstorageprod.blob.core.windows.net/assets/1/rushmoreSample_1.mp4" type="video/mp4"/>
                </div>
                {*/}

                <div style={{margin: "0px 0px 0px 0px"}}>
                    <page-container>

                        <div className="stat bg-neutral align-top" style={{height: "100px"}}>
                            <img className="-mt-1" width="200px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/Amplify_transaprent.png"/>

                        </div>

                        <div className="signinBody">

                            <div className="stats w-96 rounded-none mb-5 pl-3 pt-5 pb-5 pr-5 signInCardBody" style={{margin: topMargin}}>

                                <div className="card-body">
                                    <h2 className="login-title">Sign In</h2>

                                    <div className="form-control w-full max-w-xs">
                                      <label className="label">
                                        <span className="label-text">Username</span>
                                      </label>
                                      <input type="text" placeholder="Username" className="input input-bordered w-full max-w-xs"
                                          autoCorrect="off"
                                          autoCapitalize="none"
                                          onChange={(e) => {
                                              setUsername(e.target.value);
                                          }}/>
                                    </div>

                                    <div className="form-control w-full max-w-xs">
                                      <label className="label">
                                        <span className="label-text">Password</span>
                                      </label>
                                      <input type="password" placeholder="Password" className="input input-bordered w-full max-w-xs"
                                          onChange={(e) => {
                                                setPassword(e.target.value);
                                            }} />
                                    </div>

                                    <div className="card-actions justify-center mt-10">
                                      <button className="btn btn-neutral btn-wide" onClick={loginClick}>Sign In</button>
                                    </div>

                                </div>

                            </div>

                        </div>
                            
                        {isMobile === false ?
                        <footer>
                            <div className="signinFooter  bg-neutral align-bottom" style={{height: "200px"}}>
                                <div className="flex">
                                    <div className="mt-8" >
                                        <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                                    </div>
                                    <div className="signinFooterTagLineRight" >
                                        <img width="300px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/RUN%20WITH%20US%20-%20INVERSE.png"/>
                                    </div>
                                </div>
                            </div>
                        </footer>
                        :
                        <footer>
                            <div className="signinFooterMobile flexwrap space-x-16 bg-neutral align-bottom">
                                <img width="200px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/AllianceForge_ElectionTech_LargeFormat_White.png"/>
                                <img width="150px" src="https://afcuploadstorageprod.blob.core.windows.net/assets/1/RUN%20WITH%20US%20-%20INVERSE.png"/>
                            </div>
                        </footer>

                        }

                    </page-container>

                    <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleErrorClose} anchorOrigin={{ vertical, horizontal: "center" }}>
                      <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                </div>
            </div>
            {/*}
            <div className="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
                <img
                    src={af_logo}
                    alt="Alliance Forge"
                    className="cursor-pointer"
                />
            </div>
            {*/}

              
        </div>
    );
}

Login.propTypes = {
   fullscreen: PropTypes.bool
}
